import React from 'react';
import Slider from 'react-slick';

const OurProject = () => {
    // render() {
        var slick_slider = {
          dots: false,
          arrow: false,
          autoplay: true,
          infinite: true,
          speed: 1000,
          slidesToShow: 4,
          slidesToScroll: 1,
          rows: 1,
  
          responsive: [ {
  
              breakpoint: 1199,
              settings: {
                  slidesToShow: 3,
                  slidesToScroll: 3
              }
          },{
      
            breakpoint: 991,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 2
            }
        },
        {
            breakpoint: 575,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1
            }
        }]
        };
        return (
            <>
                {/* job-list-section */}
                <section className="ttm-row job-list-section bg-theme-GreyColor ttm-bg z-index-1 clearfix">
                    <div className="container">
                        {/* row */}
                        <div className="row">
                            <div className="col-lg-11">
                                {/* section title */}
                                <div className="section-title style2 mb-0">
                                    <div className="title-header">
                                        <h3>Find The <span className="text-theme-SkinColor">Best One!</span></h3>
                                        <h2 className="title"> Our Projects </h2>
                                    </div>
                                    <div className="title-desc">
                                        {/* <p>We have over 30 years experience of Financial ,Marketing and Busness Solutions expertise,
                                            our goal is to maxnci reputation and financial.</p> */}
                                    </div>
                                </div>{/* section title end */}
                            </div>
                        </div>{/* row end */}
                        {/* row */}
                        <Slider className="row slick_slider slick-arrows-style2 pt-20 mb_10" {...slick_slider} rows={1} slidesToShow={3} arrows={true}>
                            <div className="col-lg-3 col-md-6 col-sm-6">
                                {/* featured-icon-box */}
                                <div className="featured-icon-box icon-align-before-content icon-ver_align-top style5">
                                    <div className="featured-icon">
                                        <div className="ttm-icon ttm-icon_element-onlytxt ttm-icon_element-color-skincolor ttm-icon_element-size-lg" >
                                            {/* <i className="fa fa-oil-well" style={{fontSize : '100px'}}></i> */}
                                            <img className="img-fluid" src="images/Project/1.png"/>
                                        </div>
                                    </div>
                                    <div className="featured-content">
                                        <div className="featured-title">
                                            <h3>Offshore Projects in the<br/> Al-Shaheen Oil Field</h3>
                                        </div>
                                        <div className="featured-desc">
                                            <p>North Oil Company</p>
                                            <p>Qatar</p>
                                            <br/>
                                        </div>
                                    </div>
                                </div>{/* featured-icon-box end */}
                            </div>
                            <div className="col-lg-3 col-md-6 col-sm-6">
                                {/* featured-icon-box */}
                                <div className="featured-icon-box icon-align-before-content icon-ver_align-top style5">
                                    <div className="featured-icon">
                                        <div className="ttm-icon ttm-icon_element-onlytxt ttm-icon_element-color-skincolor ttm-icon_element-size-lg">
                                            {/* <i className="flaticon flaticon-expert"></i> */}
                                            <img className="img-fluid" src="images/Project/2.png"/>
                                        </div>
                                    </div>
                                    <div className="featured-content">
                                        <div className="featured-title">
                                            <h3>Installation of Offshore<br/> Platforms </h3>
                                        </div>
                                        <div className="featured-desc">
                                            <p>Gallaf Batch 2</p>
                                            <p>Qatar</p>
                                            <br/>
                                        </div>
                                    </div>
                                </div>{/* featured-icon-box end */}
                            </div>
                            <div className="col-lg-3 col-md-6 col-sm-6">
                                {/* featured-icon-box */}
                                <div className="featured-icon-box icon-align-before-content icon-ver_align-top style5">
                                    <div className="featured-icon">
                                        <div className="ttm-icon ttm-icon_element-onlytxt ttm-icon_element-color-skincolor ttm-icon_element-size-lg">
                                            {/* <i className="flaticon flaticon-budget"></i> */}
                                            <img className="img-fluid" src="images/Project/3.png"/>
                                        </div>
                                    </div>
                                    <div className="featured-content">
                                        <div className="featured-title">
                                            <h3>Offshore Marine Personnel<br></br> on DP3 Vessels</h3>
                                        </div>
                                        <div className="featured-desc">
                                            <p>U.A.E., Qatar, Mexico, Angola</p>
                                            <br/>
                                            {/* <br/> */}
                                        </div>
                                    </div>
                                </div>{/* featured-icon-box end */}
                            </div>
                            <div className="col-lg-3 col-md-6 col-sm-6">
                                {/* featured-icon-box */}
                                <div className="featured-icon-box icon-align-before-content icon-ver_align-top style5">
                                    <div className="featured-icon">
                                        <div className="ttm-icon ttm-icon_element-onlytxt ttm-icon_element-color-skincolor ttm-icon_element-size-lg">
                                            {/* <i className="flaticon flaticon-house"></i> */}
                                            <img className="img-fluid" src="images/Project/4.png"/>
                                        </div>
                                    </div>
                                    <div className="featured-content">
                                        <div className="featured-title">
                                            <h3>Construction Projects</h3>
                                        </div>
                                        <div className="featured-desc">
                                            <p>Kingdom of Saudi Arabia</p>
                                            <br/>
                                            <br/>
                                            <br/>
                                        </div>
                                    </div>
                                </div>{/* featured-icon-box end */}
                            </div>
                            <div className="col-lg-3 col-md-6 col-sm-6">
                                {/* featured-icon-box */}
                                <div className="featured-icon-box icon-align-before-content icon-ver_align-top style5">
                                    <div className="featured-icon">
                                        <div className="ttm-icon ttm-icon_element-onlytxt ttm-icon_element-color-skincolor ttm-icon_element-size-lg">
                                            {/* <i className="flaticon flaticon-human-resources-4"></i> */}
                                            <img className="img-fluid" src="images/Project/5.png"/>
                                        </div>
                                    </div>
                                    <div className="featured-content">
                                        <div className="featured-title">
                                            <h3>Accomodation Support Vessels</h3>
                                        </div>
                                        <div className="featured-desc">
                                            <p>U.A.E. & Qatar</p>
                                            <br/>
                                            <br/>
                                            
                                        </div>
                                    </div>
                                </div>{/* featured-icon-box end */}
                            </div>
                            <div className="col-lg-3 col-md-6 col-sm-6">
                                {/* featured-icon-box */}
                                <div className="featured-icon-box icon-align-before-content icon-ver_align-top style5">
                                    <div className="featured-icon">
                                        <div className="ttm-icon ttm-icon_element-onlytxt ttm-icon_element-color-skincolor ttm-icon_element-size-lg">
                                            {/* <i className="flaticon flaticon-telecommunication"></i> */}
                                            <img className="img-fluid" src="images/Project/6.png"/>
                                        </div>
                                    </div>
                                    <div className="featured-content">
                                        <div className="featured-title">
                                            <h3>Summer Camp <br/>& Shutdown Projects</h3>
                                        </div>
                                        <div className="featured-desc">
                                            <p>Qatar Gas now Qatar Energy LNG</p>
                                            <br/>
                                            {/* <br/> */}
                                        </div>
                                    </div>
                                </div>{/* featured-icon-box end */}
                            </div>
                            <div className="col-lg-3 col-md-6 col-sm-6">
                                {/* featured-icon-box */}
                                <div className="featured-icon-box icon-align-before-content icon-ver_align-top style5">
                                    <div className="featured-icon">
                                        <div className="ttm-icon ttm-icon_element-onlytxt ttm-icon_element-color-skincolor ttm-icon_element-size-lg">
                                            {/* <i className="flaticon flaticon-restaurant"></i> */}
                                            <img className="img-fluid" src="images/Project/7.png"/>
                                        </div>
                                    </div>
                                    <div className="featured-content">
                                        <div className="featured-title">
                                            <h3>North Field Expansion Project</h3>
                                        </div>
                                        <div className="featured-desc">
                                            <p>Qatar</p>
                                            <br/>
                                            <br/>
                                        </div>
                                    </div>
                                </div>{/* featured-icon-box end */}
                            </div>
                            
                        </Slider>{/* row end */}
                    </div>
                </section>
                {/* job-list end */}
            </>

        );
    // }
}

export default OurProject;