import React, { Component } from 'react'
import Slider from 'react-slick'
// import ProgressBar from 'react-animated-progress-bar'
import Header from '../components/layout/Header'
import PageHeader from '../components/layout/PageHeader'
import { Footer } from '../components/layout/Footer'
// import { Tab, Tabs, TabList, TabPanel } from 'react-tabs'
// import CountUp from 'react-countup'

export class Services extends Component {
  constructor (props) {
    super(props)

    this.state = {
      photoIndex: 0,
      isOpen: false
    }
  }
  render () {
    var slick_slider = {
      dots: false,
      arrow: false,
      autoplay: true,
      infinite: true,
      speed: 1000,
      slidesToShow: 4,
      slidesToScroll: 1,
      rows: 1,

      responsive: [{
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3
        }
      }, {
        breakpoint: 778,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2
        }
      },
        {
          breakpoint: 575,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
          }
        }]
    }

    return (
      <div className='site-main'>
        <Header />
        {/* PageHeader */}
        <PageHeader title='Our Expertise' breadcrumb='Services' />
        {/* PageHeader end */}
        {/* services-section */}
        <section className='ttm-row services-section bg-img1 bg-theme-GreyColor ttm-bg ttm-bgimage-yes clearfix' style={{ backgroundImage: 'url(images/bg-image/row-bgimage-1.png)' }}>
          <div className='container'>
            {/* row */}
            <div className='row'>
              <div className='col-lg-11'>
                {/* section title */}
                <div className='section-title style2 mb-0'>
                  <div className='title-header'>
                    <h3>Our <span className='text-theme-SkinColor'> Expertise</span></h3>
                    <h2 className='title'>We Understand Needs</h2>
                  </div>
                  <div className='title-desc'>
                    <p>
                      Offering a spectrum of specialized solutions, from <span className='text-theme-SkinColor'>drilling and marine operations to construction and workforce management</span>,
                      ensuring efficiency, reliability, and sustainability across diverse industries.
                    </p>
                  </div>
                </div>
                {/* section title end */}
              </div>
            </div>
            {/* row end */}
            {/* Slider */}
            <Slider
              className='row slick_slider slick-arrows-style2 pt-20 mb_10'
              {...slick_slider}
              slidesToShow={2}
              rows={3}
              arrows={true}
              autoplay={false}
              responsive={[{ breakpoint: 1024, settings: { slidesToShow: 2, slidesToScroll: 2 } },
 { breakpoint: 575, settings: { slidesToShow: 1, slidesToScroll: 1 } }]}>
              <div className='col-md-12'>
                {/* featured-imagebox */}
                <div className='featured-imagebox featured-imagebox-services style1'>
                  <div className='featured-thumbnail'>
                    <img src={"images/Service/1.png"} />
                  </div>
                  {/* featured-thumbnail end */}
                  <div className='featured-content'>
                    <div className='featured-title'>
                      <h3><a href={process.env.PUBLIC_URL + '/services_details'}>DRILLING & WELL SERVICE</a></h3>
                    </div>
                    <div className='featured-desc'>
                      <p>
                        Leading-edge solutions for efficient drilling and optimal well performance.
                      </p>
                    </div>
                    <a className='ttm-btn btn-inline ttm-btn-size-md ttm-btn-color-darkgrey' href={process.env.PUBLIC_URL + '/services_details'}>read more!</a>
                  </div>
                </div>
                {/* featured-imagebox end */}
              </div>
              <div className='col-md-12'>
                {/* featured-imagebox */}
                <div className='featured-imagebox featured-imagebox-services style1'>
                  <div className='featured-thumbnail'>
                    <img className='img-fluid' src='images/Service/2.png' alt='image' />
                  </div>
                  {/* featured-thumbnail end */}
                  <div className='featured-content'>
                    <div className='featured-title'>
                      <h3><a href={process.env.PUBLIC_URL + '/services_details'}>MARINE SERVICE</a></h3>
                    </div>
                    <div className='featured-desc'>
                      <p>
                        Comprehensive marine services for seamless vessel operations and offshore support.
                      </p>
                    </div>
                    <a className='ttm-btn btn-inline ttm-btn-size-md ttm-btn-color-darkgrey' href={process.env.PUBLIC_URL + '/services_details'}>read more!</a>
                  </div>
                </div>
                {/* featured-imagebox end */}
              </div>
              <div className='col-md-12'>
                {/* featured-imagebox */}
                <div className='featured-imagebox featured-imagebox-services style1'>
                  <div className='featured-thumbnail'>
                    <img className='img-fluid' src='images/Service/3.png' alt='image' />
                  </div>
                  {/* featured-thumbnail end */}
                  <div className='featured-content'>
                    <div className='featured-title'>
                      <h3><a href={process.env.PUBLIC_URL + '/services_details'}>DREDGING SERVICE</a></h3>
                    </div>
                    <div className='featured-desc'>
                      <p>
                        Expertise in dredging techniques for enhanced waterway accessibility.
                      </p>
                    </div>
                    <a className='ttm-btn btn-inline ttm-btn-size-md ttm-btn-color-darkgrey' href={process.env.PUBLIC_URL + '/services_details'}>read more!</a>
                  </div>
                </div>
                {/* featured-imagebox end */}
              </div>
              <div className='col-md-12'>
                {/* featured-imagebox */}
                <div className='featured-imagebox featured-imagebox-services style1'>
                  <div className='featured-thumbnail'>
                    <img className='img-fluid' src='images/Service/4.png' alt='image' />
                  </div>
                  {/* featured-thumbnail end */}
                  <div className='featured-content'>
                    <div className='featured-title'>
                      <h3><a href={process.env.PUBLIC_URL + '/services_details'}>Construction</a></h3>
                    </div>
                    <div className='featured-desc'>
                      <p>
                        Innovative construction solutions for enduring infrastructures and safer communities.
                      </p>
                    </div>
                    {/* <a className="ttm-btn btn-inline ttm-btn-size-md ttm-btn-color-darkgrey"
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                            href={process.env.PUBLIC_URL + '/services_details'}>read more!</a> */}
                  </div>
                </div>
                {/* featured-imagebox end */}
              </div>
              <div className='col-md-12'>
                {/* featured-imagebox */}
                <div className='featured-imagebox featured-imagebox-services style1'>
                  <div className='featured-thumbnail'>
                    <img className='img-fluid' src='images/Service/5.png' alt='image' />
                  </div>
                  {/* featured-thumbnail end */}
                  <div className='featured-content'>
                    <div className='featured-title'>
                      <h3><a href={process.env.PUBLIC_URL + '/services_details'}>White & Blue Collar</a></h3>
                    </div>
                    <div className='featured-desc'>
                      <p>
                        Tailored manpower services catering to both white-collar and blue-collar sectors.
                      </p>
                    </div>
                    <a className='ttm-btn btn-inline ttm-btn-size-md ttm-btn-color-darkgrey' href={process.env.PUBLIC_URL + '/services_details'}>read more!</a>
                  </div>
                </div>
                {/* featured-imagebox end */}
              </div>
              <div className='col-md-12'>
                {/* featured-imagebox */}
                <div className='featured-imagebox featured-imagebox-services style1'>
                  <div className='featured-thumbnail'>
                    {/* <img className='img-fluid' src='images/Service/1.png' alt='image' /> */}
                  </div>
                  {/* featured-thumbnail end */}
                  <div className='featured-content'>
                    <div className='featured-title'>
                      {/* <h3><a href={process.env.PUBLIC_URL + '/services_details'}>Retained search</a></h3> */}
                    </div>
                    <div className='featured-desc'>
                      {/* <p>
                                                                                                                                                                                                                                                                                                                                            HireCo tens to accelerate innovation current and future based solutions to support customers.
                                                                                                                                                                                                                                                                                                                                          </p> */}
                    </div>
                    {/* <a className='ttm-btn btn-inline ttm-btn-size-md ttm-btn-color-darkgrey' href={process.env.PUBLIC_URL + '/services_details'}>read more!</a> */}
                  </div>
                </div>
                {/* featured-imagebox end */}
              </div>
            </Slider>
            {/* Slider end */}
          </div>
        </section>
        {/* services-section end */}
        {/* cta-section */}
        <section className='ttm-row cta-section bg-img7 bg-theme-DarkColor ttm-bg ttm-bgimage-yes text-theme-WhiteColor clearfix' style={{ backgroundImage: 'url("images/Service/getintouch.png")' }}>
          <div className='ttm-row-wrapper-bg-layer ttm-bg-layer bg-theme-DarkColor'></div>
          <div className='container'>
            {/* row */}
            <div className='row'>
              <div className='col-lg-8 m-auto row-title'>
                {/* section-title */}
                <div className='section-title title-style-center_text'>
                  <div className='title-header'>
                    {/* <h3><span className='text-theme-SkinColor'>More than 50,000 happy client</span></h3> */}
                    <h4 className='title'>Contact us for tailor-made solutions that cater to your unique staffing needs.<span className='text-theme-SkinColor'></span></h4>
                  </div>
                </div>
                {/* section-title end */}
                <div className='text-center pt-10'>
                  <a className='ttm-btn ttm-btn-size-md ttm-btn-shape-rounded ttm-btn-style-border ttm-btn-color-white' href={process.env.PUBLIC_URL + '/contact_01'}>contact us</a>
                </div>
              </div>
            </div>
            {/* row end */}
          </div>
        </section>
        {/* cta-section end */}
        {/* blog-section */}
        {/* blog-section end */}
        {/* action-section */}
        <section className='ttm-row action-section bg-theme-SkinColor text-theme-WhiteColor clearfix'>
          <div className='container'>
            <div className='row'>
              <div className='col-lg-12'>
                <div className='d-md-flex align-items-center justify-content-between'>
                  {/* featured-icon-box */}
                  <div className='featured-icon-box icon-align-before-content style2'>
                    <div className='featured-icon'>
                      <div className='ttm-icon ttm-icon_element-onlytxt ttm-icon_element-color-white ttm-icon_element-size-xl'>
                        <i className='flaticon flaticon-recruitment-5'></i>
                      </div>
                    </div>
                    <div className='featured-content'>
                      <div className='featured-title'>
                        <h3>Incredible Recruitment & Staffing Specialist.</h3>
                      </div>
                      <div className='featured-desc'>
                        {/* <p>
                          We have over 30 years experience oft Business porro qusquam dol ipsum quia dolor sit amet.
                        </p> */}
                      </div>
                    </div>
                  </div>
                  {/* featured-icon-box end */}
                  <a className='ttm-btn ttm-btn-size-md ttm-btn-shape-rounded ttm-btn-style-border ttm-btn-color-white' href='/carrer_01?focus=true'>Hiring Now!</a>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* action-section end */}
        <Footer />
      </div>
    )
  }
}

export default Services
