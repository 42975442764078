
import React from "react";
const ServicesDetail = () =>
{
    return(
        <>
          <div className="col-lg-8 content-area order-lg-2">
                                {/* ttm-service-single-content-area */}
                                <div className="ttm-service-single-content-area">
                                    <div className="ttm_single_image-wrapper">
                                        <img className="img-fluid" src="images/ServicesDetail/1.png" alt="services-img" />
                                    </div>
                                    <div className="ttm-service-description mt-40">
                                        <h3 className="mb-20">Description For Our Service</h3>
                                        <p>By prioritizing recruitment, training, safety, and retention, Atlas ensures a skilled and reliable manpower pool for drilling and well services, enabling successful and efficient operations in the oil and gas industry.</p>
                                        <div className="row">
                                            <div className="col-lg-6 col-md-6 col-sm-6">
                                                {/* featured-icon-box */} 
                                                <div className="featured-icon-box icon-align-before-content icon-ver_align-top mr-50 mr-md-0 mt-20">
                                                    <div className="featured-icon">
                                                        <div className="ttm-icon ttm-icon_element-onlytxt ttm-icon_element-color-skincolor ttm-icon_element-size-lg">
                                                            <i className="flaticon-research"></i>
                                                        </div>
                                                    </div>
                                                    <div className="featured-content">
                                                        <div className="featured-title">
                                                            <h3>Recruitment Expertise</h3>
                                                        </div>
                                                        <div className="featured-desc">
                                                            <p>Atlas specializes in sourcing and recruiting highly skilled personnel with expertise in drilling and well services. Our recruitment team identifies candidates with relevant qualifications, experience, and technical competencies to meet the specific needs of each project.</p>
                                                        </div>
                                                    </div>
                                                </div>{/* featured-icon-box end */}
                                            </div>
                                            <div className="col-lg-6 col-md-6 col-sm-6">
                                                {/* featured-icon-box */}
                                                <div className="featured-icon-box icon-align-before-content icon-ver_align-top mr-50 mr-md-0 mt-20">
                                                    <div className="featured-icon">
                                                        <div className="ttm-icon ttm-icon_element-onlytxt ttm-icon_element-color-skincolor ttm-icon_element-size-lg">
                                                            <i className="flaticon-healthcare"></i>
                                                        </div>
                                                    </div>
                                                    <div className="featured-content">
                                                        <div className="featured-title">
                                                            <h3>Qualified Personnel</h3>
                                                        </div>
                                                        <div className="featured-desc">
                                                            <p>We prioritize hiring personnel with the necessary certifications, training, and experience in drilling operations. This includes drillers, toolpushers, derrickmen, mud engineers, and other essential roles involved in drilling and well services.</p>
                                                        </div>
                                                    </div>
                                                </div>{/* featured-icon-box end */}
                                            </div>
                                            <div className="col-lg-6 col-md-6 col-sm-6">
                                                {/* featured-icon-box */}
                                                <div className="featured-icon-box icon-align-before-content icon-ver_align-top mr-50 mr-md-0 mt-20">
                                                    <div className="featured-icon">
                                                        <div className="ttm-icon ttm-icon_element-onlytxt ttm-icon_element-color-skincolor ttm-icon_element-size-lg">
                                                            <i className="flaticon-conveyor"></i>
                                                        </div>
                                                    </div>
                                                    <div className="featured-content">
                                                        <div className="featured-title">
                                                            <h3>Safety Training</h3>
                                                        </div>
                                                        <div className="featured-desc">
                                                            <p>Safety is paramount in drilling and well services. Atlas ensures that all personnel undergo rigorous safety training to adhere to industry standards and regulations. This includes training on hazard recognition, emergency procedures, and personal protective equipment (PPE) usage.</p>
                                                        </div>
                                                    </div>
                                                </div>{/* featured-icon-box end */}
                                            </div>
                                            <div className="col-lg-6 col-md-6 col-sm-6">
                                                {/* featured-icon-box */}
                                                <div className="featured-icon-box icon-align-before-content icon-ver_align-top mr-50 mr-md-0 mt-20">
                                                    <div className="featured-icon">
                                                        <div className="ttm-icon ttm-icon_element-onlytxt ttm-icon_element-color-skincolor ttm-icon_element-size-lg">
                                                            <i className="flaticon-human-resources-3"></i>
                                                        </div>
                                                    </div>
                                                    <div className="featured-content">
                                                        <div className="featured-title">
                                                            <h3>Continuous Development</h3>
                                                        </div>
                                                        <div className="featured-desc">
                                                            <p>We invest in the continuous training and development of our manpower to enhance their skills and keep them updated with the latest technologies and best practices in drilling and well services. This includes technical training on drilling equipment, well control techniques, and environmental regulations.</p>
                                                        </div>
                                                    </div>
                                                </div>{/* featured-icon-box end */}
                                            </div>
                                            <div className="col-lg-6 col-md-6 col-sm-6">
                                                {/* featured-icon-box */}
                                                <div className="featured-icon-box icon-align-before-content icon-ver_align-top mr-50 mr-md-0 mt-20">
                                                    <div className="featured-icon">
                                                        <div className="ttm-icon ttm-icon_element-onlytxt ttm-icon_element-color-skincolor ttm-icon_element-size-lg">
                                                            <i className="flaticon-human-resources-3"></i>
                                                        </div>
                                                    </div>
                                                    <div className="featured-content">
                                                        <div className="featured-title">
                                                            <h3>Retention Strategies</h3>
                                                        </div>
                                                        <div className="featured-desc">
                                                            <p>Retaining skilled manpower is essential for maintaining continuity and efficiency in drilling operations. Atlas implements retention strategies such as competitive compensation packages, career development opportunities, and a positive work environment to ensure high employee satisfaction and retention rates.</p>
                                                        </div>
                                                    </div>
                                                </div>{/* featured-icon-box end */}
                                            </div>
                                            <div className="col-lg-6 col-md-6 col-sm-6">
                                                {/* featured-icon-box */}
                                                <div className="featured-icon-box icon-align-before-content icon-ver_align-top mr-50 mr-md-0 mt-20">
                                                    <div className="featured-icon">
                                                        <div className="ttm-icon ttm-icon_element-onlytxt ttm-icon_element-color-skincolor ttm-icon_element-size-lg">
                                                            <i className="flaticon-human-resources-3"></i>
                                                        </div>
                                                    </div>
                                                    <div className="featured-content">
                                                        <div className="featured-title">
                                                            <h3>Manpower Planning</h3>
                                                        </div>
                                                        <div className="featured-desc">
                                                            <p>Atlas conducts thorough manpower planning to anticipate staffing needs for upcoming drilling projects. This includes forecasting project requirements, assessing skill gaps, and implementing recruitment strategies to ensure the availability of qualified personnel when needed.</p>
                                                        </div>
                                                    </div>
                                                </div>{/* featured-icon-box end */}
                                            </div>
                                            <div className="col-lg-6 col-md-6 col-sm-6">
                                                {/* featured-icon-box */}
                                                <div className="featured-icon-box icon-align-before-content icon-ver_align-top mr-50 mr-md-0 mt-20">
                                                    <div className="featured-icon">
                                                        <div className="ttm-icon ttm-icon_element-onlytxt ttm-icon_element-color-skincolor ttm-icon_element-size-lg">
                                                            <i className="flaticon-human-resources-3"></i>
                                                        </div>
                                                    </div>
                                                    <div className="featured-content">
                                                        <div className="featured-title">
                                                            <h3>Performance Monitoring</h3>
                                                        </div>
                                                        <div className="featured-desc">
                                                            <p>We monitor the performance of our manpower closely to ensure productivity, quality, and adherence to safety protocols. This includes regular performance evaluations, feedback mechanisms, and corrective actions to address any issues or challenges that may arise.</p>
                                                        </div>
                                                    </div>
                                                </div>{/* featured-icon-box end */}
                                            </div>
                                        </div>
                                        <div className="ttm-horizontal_sep width-100 mt-25 mb-35"></div>
                                        <div className="row">
                                            <div className="col-md-6">
                                                <h4>Drilling & Well Services</h4>
                                                <p>Drilling operations encompass crucial roles essential across the three primary phases of the E&P (Exploration & Production) process. Beyond technical expertise, proficiency in cost and budget control, process planning, and (Q)HSE (Quality, Health, Safety, and Environment) management are integral to these operations. We continuously seek individuals who excel in Exploration, Development, and Production stages of the E&P process, ensuring comprehensive support across all facets of drilling endeavours.</p>
                                                <h4>How to get there?</h4>
                                                <ul className="ttm-list ttm-list-style-square ttm-list-icon-color-skincolor mb-30">
                                                    <li>
                                                        <div className="ttm-list-li-content">Specific positions within the offshore oil & gas industry entail varying background and educational requirements. For roles such as Roughnecks, Roustabouts, or (Assistant) Drillers, formal education is not mandatory. Many individuals commence their offshore career in  junior positions, gradually advancing to leadership roles like Drillers or Supervisors and above. Higher engineering or supervisory positions typically necessitate a relevant science or engineering degree, coupled with pertinent work experience.</div>
                                                    </li>
                                                    <li>
                                                        <div className="ttm-list-li-content">Proficiency in multiple languages is advantageous for international assignments, although English remains the industry's primary language. Education serves as another pathway into the industry, particularly for individuals lacking prior oil and gas experience. Numerous universities and educational institutions offer courses tailored to entry into the sector.</div>
                                                    </li>
                                                    <li>
                                                        <div className="ttm-list-li-content">Working offshore demands physical and mental fitness due to prolonged periods away from home and the physically demanding and extreme working conditions. However, this industry offers lucrative salaries and exciting career prospects for individuals with a penchant for travel and career advancement.</div>
                                                    </li>
                                                   
                                                </ul>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="ttm_single_image-wrapper mb-30">
                                                    <img className="img-fluid" src="images/ServicesDetail/1.svg" alt="services-img" />
                                                </div>
                                            </div>
                                            {/* <div className="col-lg-12">
                                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt utlabore
                                                    et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus com </p>
                                            </div> */}
                                        </div>
                                    </div>
                                </div>
                                {/* ttm-service-single-content-are end */}
                            </div>
        </>
    )

}

export default ServicesDetail;