import React from 'react'

function FAQ ({ faq, index, toggleFAQ }) {
  return (
    <div
      className={'faq ' + (faq.open ? 'open' : '')}
      key={index}
      onClick={() => toggleFAQ(index)}
      style={{
 margin: '15px',
 padding: '15px',
 backgroundColor: '#FFF',
 borderRadius: '8px',
 boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.2)',
 cursor: 'pointer' 
 }}>
{/* <div className={`faq ${faq.open ? 'open' : ''}`} onClick={toggleFAQ}> */}
      <div className='faq-question' style={{ position: 'relative', fontSize: '20px', paddingRight: '80px', transition: 'all 0.4s ease' }}>
        {faq.question}
        <span style={{ position: 'absolute', top: '50%', right: '0px', transform: 'translateY(-50%)', width: '30px', height: '30px', backgroundImage: 'url("./arrow-down-mint.svg")', backgroundPosition: 'center', backgroundSize: 'contain', backgroundRepeat: 'no-repeat', transition: 'all 0.4s ease-out', transform: faq.open ? 'translateY(-50%) rotate(180deg)' : 'translateY(-50%)' }}></span>
      </div>
      <div className='faq-answer' style={{ opacity: faq.open ? '1' : '0', maxHeight: faq.open ? '1000px' : '0', overflowY: 'hidden', transition: 'all 0.4s ease-out' }}>
        {faq.answer}
      </div>
    </div>
  )
}

export default FAQ
