import React, { Component } from 'react';
import Slider from 'react-slick';
// import ProgressBar from 'react-animated-progress-bar';
// import Header from '../components/layout/Header3';
import Header from '../components/layout/Header3';
// import { Banner } from '../components/banner/Home2_banner';
import { Banner } from '../components/banner/Home3_banner';
import { Footer } from '../components/layout/Footer';
// import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import CountUp from 'react-countup';
import Video from '../components/layout/Video';
// 
import MissionVision from '../components/MissionVision';
import OurProject from '../components/OurProject';

// import OurProject from '../components/OurProject';
// import ClientSection from '../components/ClientSection';

export class Home2 extends Component {
    constructor(props) {
        super(props);

        this.state = {
            photoIndex: 0,
            isOpen: false,
            currentSlide: 0,
        };
    }


    handleAfterChange = (index) => {
        console.log("after change", index);
        this.setState({
            currentSlide: index
        });
    };

    render() {
        const { photoIndex, isOpen } = this.state;
        const settings = {
            beforeChange: function (currentSlide, nextSlide) {
                console.log("before change", currentSlide, nextSlide);
            },
            afterChange: this.handleAfterChange
        };


        var slick_slider = {
            dots: false,
            arrow: false,
            autoplay: true,
            infinite: true,
            speed: 800,
            slidesToScroll: 1,
            slidesToShow: 4,
            rows: 1,

            responsive: [{

                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2
                }
            },
            {
                breakpoint: 575,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }]
        };

        return (
            <div className="site-main">

                <Header />


                {/* Banner */}
                <Banner />
                {/* Banner end */}


                {/* about-section */}
                <section className="ttm-row about-section clearfix">
                    <div className="container">
                        {/* row */}
                        <div className="row">
                            <div className="col-lg-6">
                                {/* section title */}
                                <div className="section-title">
                                    <div className="title-header">
                                        <h3>who <span className="text-theme-SkinColor">we are!</span></h3>
                                        <h2 className="title"> Atlas : Streamlining Resources for Business Success.</h2>
                                    </div>
                                    <div className="title-desc">
                                        <p>Atlas is a professional offshore maritime recruitment specialist dedicated to bridging the gap between skilled offshore crew and the evolving needs of the maritime industry. With a relentless commitment to<span className="text-theme-SkinColor"><strong> Quality, Occupational Health & Environmental Safety, </strong></span> we specialise in sourcing and deploying <span className="text-theme-SkinColor"><strong>trained, competent and skilled </strong></span>individuals across a wide spectrum of positions and ratings.</p>
                                    </div>
                                </div>{/* section title end */}
                                <div className="mb-35">
                                    <ul className="ttm-list ttm-list-style-icon ttm-list-icon-color-skincolor text-theme-DarkColor">
                                        <li><i className="far fa-check-circle"></i>
                                            <div className="ttm-list-li-content">Drilling: All senior & junior positions in the deck, drilling, maintenance and support crew.
</div>
                                        </li>
                                        <li><i className="far fa-check-circle"></i>
                                            <div className="ttm-list-li-content">Marine: Navigation & Engine - Officers & ratings.
</div>
                                        </li>
                                        <li><i className="far fa-check-circle"></i>
                                            <div className="ttm-list-li-content">Construction Workers: Wide range of roles.</div>
                                        </li>
                                            
                                    </ul>
                                </div>
                                <a className="ttm-btn ttm-btn-size-md ttm-btn-shape-rounded ttm-btn-style-border ttm-btn-color-dark"
                                    href={process.env.PUBLIC_URL + '/about_us'}>view more</a>
                            </div>
                            <div className="col-lg-6 col-md-9 col-sm-10 col-12 mx-auto">
                                <div className="mr-40 ml-20 pb-60 pt-lg-50">
                                    <div className="d-flex justify-content-between" style={{ backgroundImage: 'url(images/slides/single-img-01-508x500.png)', backgroundSize: 'cover' }}>
                                        <div className="pt-20 pr-20 bg-theme-WhiteColor ml_20 mb_60 mt-200">
                                            <img src="images/slides/single-img-02.png" className="img-fluid" alt="bgimage" />
                                        </div>
                                        <div className="d-flex align-items-start h-100 mr_30 pt-50">
                                            <div className="ttm-play-icon-btn p-20 bg-theme-SkinColor">
                                                <Video className="flaticon-play-button text-theme-WhiteColor" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>{/* row end */}
                    </div>
                </section>
                {/* about-section end */}


                {/* features-section */}
                <section className="ttm-row features-section bg-theme-GreyColor clearfix" >
                    <div className="container">
                        {/* row */}
                        <div className="row">
                            <div className="col-lg-12">
                                {/* section title */}
                                <div className="section-title title-style-center_text">
                                    <div className="title-header">
                                        <h3>What <span className="text-theme-SkinColor">we serve!</span></h3>
                                        <h2 className="title">Our Services</h2>
                                    </div>
                                </div>{/* section title end */}
                            </div>
                        </div>{/* row end */}
                        {/* row */}
                        <div className="row row-equal-height mb_10">
                            <div className="col-lg-4 col-md-6 col-sm-6">
                                {/* featured-icon-box */}
                                <div className="featured-icon-box icon-align-before-title style3">
                                    <div className="featured-icon">
                                        <div className="ttm-icon ttm-icon_element-onlytxt ttm-icon_element-color-skincolor ttm-icon_element-size-lg">
                                            {/* <i className="flaticon-research"></i> */}
                                            {/* <i className="flaticon-drill"></i> */}
                                            <img className="img-fluid" src="images/font/5.svg"/>
                                            
                                        </div>
                                    </div>
                                    <div className="featured-title">
                                        <h3>DRILLING & WELL SERVICE</h3>
                                    </div>
                                    <div className="featured-content">
                                        <div className="featured-desc">
                                            <p>Expert solutions for drilling and well operations, ensuring efficiency and safety throughout.</p>
                                        </div>
                                    </div>
                                </div>{/* featured-icon-box end */}
                            </div>
                            <div className="col-lg-4 col-md-6 col-sm-6">
                                {/* featured-icon-box */}
                                <div className="featured-icon-box icon-align-before-title style3">
                                    <div className="featured-icon">
                                        <div className="ttm-icon ttm-icon_element-onlytxt ttm-icon_element-color-skincolor ttm-icon_element-size-lg">
                                            {/* <i className="flaticon-recruitment-7"></i> */}
                                            <img className="img-fluid" src="images/font/4.svg"/>
                                        </div>
                                    </div>
                                    <div className="featured-title">
                                        <h3>MARINE SERVICE</h3>
                                    </div>
                                    <div className="featured-content">
                                        <div className="featured-desc">
                                            <p>Comprehensive marine solutions tailored to your specific needs, delivering excellence in every voyage.</p>
                                        </div>
                                    </div>
                                </div>{/* featured-icon-box end */}
                            </div>
                            <div className="col-lg-4 col-md-6 col-sm-6">
                                {/* featured-icon-box */}
                                <div className="featured-icon-box icon-align-before-title style3">
                                    <div className="featured-icon">
                                        <div className="ttm-icon ttm-icon_element-onlytxt ttm-icon_element-color-skincolor ttm-icon_element-size-lg">
                                            {/* <i className="flaticon-recruiting"></i> */}
                                            {/* <i className="flaticon-technical-support"></i> */}
                                            <img className="img-fluid" src="images/font/2.svg"/>
                                        </div>
                                    </div>
                                    <div className="featured-title">
                                        <h3>DREDGING SERVICE</h3>
                                    </div>
                                    <div className="featured-content">
                                        <div className="featured-desc">
                                            <p>Specialized dredging services designed to meet the unique challenges of marine construction projects.</p>
                                        </div>
                                    </div>
                                </div>{/* featured-icon-box end */}
                            </div>    
                            <div className="col-lg-4 col-md-6 col-sm-6">
                                {/* featured-icon-box */}
                                <div className="featured-icon-box icon-align-before-title style3">
                                    <div className="featured-icon">
                                        <div className="ttm-icon ttm-icon_element-onlytxt ttm-icon_element-color-skincolor ttm-icon_element-size-lg">
                                            {/* <i className="flaticon-recruiting"></i> */}
                                            {/* <i className="flaticon-technical-support"></i> */}
                                            <img className="img-fluid" src="images/font/construction.svg"/>
                                        </div>
                                    </div>
                                    <div className="featured-title">
                                        <h3>CONSTRUCTION</h3>
                                    </div>
                                    <div className="featured-content">
                                        <div className="featured-desc">
                                            <p>Building tomorrow's world today, our construction expertise combines innovation and precision, shaping resilient infrastructures for thriving communities.</p>
                                        </div>
                                    </div>
                                </div>{/* featured-icon-box end */}
                            </div>           
                            <div className="col-lg-4 col-md-6 col-sm-6">
                                {/* featured-icon-box */}
                                <div className="featured-icon-box icon-align-before-title style3">
                                    <div className="featured-icon">
                                        <div className="ttm-icon ttm-icon_element-onlytxt ttm-icon_element-color-skincolor ttm-icon_element-size-lg">
                                            {/* <i className="flaticon-recruiting"></i> */}
                                            {/* <i className="flaticon-technical-support"></i> */}
                                            <img className="img-fluid" src="images/font/3.svg"/>
                                        </div>
                                    </div>
                                    <div className="featured-title">
                                        <h3>WHITE & BLUE COLLAR</h3>
                                    </div>
                                    <div className="featured-content">
                                        <div className="featured-desc">
                                            <p>Empowering industries with skilled white-collar and blue-collar workers, we provide tailored manpower solutions for diverse sectoral needs, ensuring efficiency and reliability. </p>
                                        </div>
                                    </div>
                                </div>{/* featured-icon-box end */}
                            </div>                                  
                        </div>
                        {/* row end */}
                    </div>
                </section>
              
                {/* features-section end */}

{/* <ClientSection/> */}
              
                {/* Mission Vision Start */}
                <MissionVision />
                {/* Mission Vision End */}

                {/* Our Project */}
                <OurProject />
                {/* our Project */}
                {/* services-section */}
                <section className="ttm-row services-section bg-img3 clearfix" style={{ backgroundImage: 'url("images/bg-image/row-bgimage-3.png")' }}>
                    <div className="container">
                        {/* row */}
                        <div className="row">
                            <div className="col-lg-12">
                                {/* section title */}
                                <div className="section-title title-style-center_text">
                                    <div className="title-header">
                                        <h3>We Serve <span className="text-theme-SkinColor">Overseas</span></h3>
                                        <h2 className="title">We Serve Worldwide</h2>
                                    </div>
                                </div>{/* section title end */}
                            </div>
                        </div>{/* row end */}
                        {/* Slider */}
                        <Slider className="row slick_slider mb_10 slick-arrows-style1" {...slick_slider} slidesToShow={3} arrows={true}>
                            <div className="col-md-12">
                                {/* featured-imagebox */}
                                <div className="featured-imagebox featured-imagebox-country">
                                    {/* featured-thumbnail */}
                                    <div className="featured-thumbnail">
                                        <img src={"images/slides/Office/1.png"} />
                                    </div>{/* featured-thumbnail end */}
                                    <div className="featured-content">
                                        <div className="featured-title">
                                            <h3><a href={process.env.PUBLIC_URL + '/'}>India - Mumbai</a></h3>
                                        </div>
                                        <div className="featured-desc">
                                            {/* <p>A millions of decision about who has a right to settle.</p> */}
                                        </div>
                                    </div>
                                </div>{/* featured-imagebox end */}
                            </div>
                            <div className="col-md-12">
                                {/* featured-imagebox */}
                                <div className="featured-imagebox featured-imagebox-country">
                                    {/* featured-thumbnail */}
                                    <div className="featured-thumbnail">
                                        <img className="img-fluid" src="images/slides/Office/2.png" alt="image" />
                                    </div>{/* featured-thumbnail end */}
                                    <div className="featured-content">
                                        <div className="featured-title">
                                            <h3><a href={process.env.PUBLIC_URL + '/'}>Qatar - Doha </a></h3>
                                        </div>
                                        <div className="featured-desc">
                                            {/* <p>Our head quarters is in Canberra, help throughout process</p> */}
                                        </div>
                                    </div>
                                </div>{/* featured-imagebox end */}
                            </div>
                            <div className="col-md-12">
                                {/* featured-imagebox */}
                                <div className="featured-imagebox featured-imagebox-country">
                                    {/* featured-thumbnail */}
                                    <div className="featured-thumbnail">
                                        <img className="img-fluid" src="images/slides/Office/3.png" alt="image" />
                                    </div>{/* featured-thumbnail end */}
                                    <div className="featured-content">
                                        <div className="featured-title">
                                            <h3><a href={process.env.PUBLIC_URL + '/'}>UAE - Dubai </a></h3>
                                        </div>
                                        <div className="featured-desc">
                                            {/* <p>We will help in every step of the application process.</p> */}
                                        </div>
                                    </div>
                                </div>{/* featured-imagebox end */}
                            </div>
                           
                            
                        </Slider>
                        {/* Slider end */}
                    </div>
                </section>
                {/* services-section end */}
              {/* <OurProject/> */}

                {/* client-section */}
                <section className="ttm-row client-section bg-theme-WhiteColor clearfix">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-4">
                                {/* section-title */}
                                <div className="section-title">
                                    <div className="title-header">
                                        {/* <h3>our <span className="text-theme-SkinColor">clients</span></h3> */}
                                        <h2 className="title">Our <span className="text-theme-SkinColor">Clients</span></h2>
                                    </div>
                                   
                                </div>{/* section-title end */}
                                {/* <a className="ttm-btn ttm-btn-size-md ttm-btn-shape-rounded ttm-btn-style-border ttm-btn-color-dark"
                                    href={process.env.PUBLIC_URL + '/'}>Explore More Companies</a> */}
                            </div>
                            {/* <div className="col-lg-8"> */}
                            <div className="col-lg-8" >

                                <div className="row g-0 ttm-vertical_sep mt-lg-50">
                                    <div className="col-sm-3">
                                        <div className="client-box">
                                            <div className="client-thumbnail">
                                                <img className="img-fluid" src="images/slides/Client/5.png" alt="image" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-sm-3">
                                        <div className="client-box">
                                            <div className="client-thumbnail">
                                                <img className="img-fluid" src="images/slides/Client/6.png" alt="image" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-sm-3">
                                        <div className="client-box">
                                            <div className="client-thumbnail">
                                            <img className="img-fluid" src="images/slides/Client/7.png" alt="image" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-sm-3">
                                        <div className="client-box">
                                            <div className="client-thumbnail">
                                            <img className="img-fluid" src="images/slides/Client/8.png" alt="image" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-sm-3">
                                        <div className="client-box">
                                            <div className="client-thumbnail">
                                                <img className="img-fluid" src="images/slides/Client/9.png" alt="image" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-sm-3">
                                        <div className="client-box">
                                            <div className="client-thumbnail">
                                                <img className="img-fluid" src="images/slides/Client/10.png" alt="image" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-sm-3">
                                        <div className="client-box">
                                            <div className="client-thumbnail">
                                            <img className="img-fluid" src="images/slides/Client/11.png" alt="image" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-sm-3">
                                        <div className="client-box">
                                            <div className="client-thumbnail">
                                            <img className="img-fluid" src="images/slides/Client/12.png" alt="image" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-sm-3">
                                        <div className="client-box">
                                            <div className="client-thumbnail">
                                            <img className="img-fluid" src="images/slides/Client/13.png" alt="image" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-sm-3">
                                        <div className="client-box">
                                            <div className="client-thumbnail">
                                            <img className="img-fluid" src="images/slides/Client/14.png" alt="image" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-sm-3">
                                        <div className="client-box">
                                            <div className="client-thumbnail">
                                            <img className="img-fluid" src="images/slides/Client/15.png" alt="image" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-sm-3">
                                        <div className="client-box">
                                            <div className="client-thumbnail">
                                            <img className="img-fluid" src="images/slides/Client/16.png" alt="image" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-sm-3">
                                        <div className="client-box">
                                            <div className="client-thumbnail">
                                            <img className="img-fluid" src="images/slides/Client/17.png" alt="image" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-sm-3">
                                        <div className="client-box">
                                            <div className="client-thumbnail">
                                            <img className="img-fluid" src="images/slides/Client/18.png" alt="image" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="ttm-horizontal_sep width-100"></div>
                                
                            </div>
                        </div>
                    </div>
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-4">
                                {/* section-title */}
                                <div className="section-title">
                                    <div className="title-header">
                                        {/* <h3>our <span className="text-theme-SkinColor">clients</span></h3> */}
                                        <h2 className="title">Our <span className="text-theme-SkinColor">Accreditations</span></h2>
                                    </div>
                                   
                                </div>{/* section-title end */}
                                {/* <a className="ttm-btn ttm-btn-size-md ttm-btn-shape-rounded ttm-btn-style-border ttm-btn-color-dark"
                                    href={process.env.PUBLIC_URL + '/'}>Explore More Companies</a> */}
                            </div>
                            <div className="col-lg-8">
                               
                                <div className="ttm-horizontal_sep width-100"></div>
                                <div className="row g-0 ttm-vertical_sep">
                                    <div className="col-sm-3">
                                        <div className="client-box">
                                            <div className="client-thumbnail">
                                                
                                                <img className="img-fluid" src="images/slides/Client/1.png" alt="image" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-sm-3">
                                        <div className="client-box">
                                            <div className="client-thumbnail">
                                                <img className="img-fluid" src="images/slides/Client/2.png" alt="image" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-sm-3">
                                        <div className="client-box">
                                            <div className="client-thumbnail">
                                                <img className="img-fluid" src="images/slides/Client/3.png" alt="image" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-sm-3">
                                        <div className="client-box">
                                            <div className="client-thumbnail">
                                                <img className="img-fluid" src="images/slides/Client/4.png" alt="image" />
                                            </div>
                                        </div>
                                    </div>
                                  
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-4">
                                {/* section-title */}
                                <div className="section-title">
                                    <div className="title-header">
                                        {/* <h3>our <span className="text-theme-SkinColor">clients</span></h3> */}
                                        <h2 className="title">Our <span className="text-theme-SkinColor">Partners</span></h2>
                                    </div>
                                   
                                </div>{/* section-title end */}
                                {/* <a className="ttm-btn ttm-btn-size-md ttm-btn-shape-rounded ttm-btn-style-border ttm-btn-color-dark"
                                    href={process.env.PUBLIC_URL + '/'}>Explore More Companies</a> */}
                            </div>
                            <div className="col-lg-8">
                               
                                <div className="ttm-horizontal_sep width-100"></div>
                                <div className="row g-0 ttm-vertical_sep">
                                    <div className="col-sm-3">
                                        <div className="client-box">
                                            <div className="client-thumbnail">
                                                
                                                <img className="img-fluid" src="images/slides/Partner/19.png" alt="image" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-sm-3">
                                        <div className="client-box">
                                            <div className="client-thumbnail">
                                                <img className="img-fluid" src="images/slides/Partner/20.png" alt="image" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-sm-3">
                                        <div className="client-box">
                                            <div className="client-thumbnail">
                                                <img className="img-fluid" src="images/slides/Partner/21.png" alt="image" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-sm-3">
                                        <div className="client-box">
                                            <div className="client-thumbnail">
                                                <img className="img-fluid" src="images/slides/Partner/22.png" alt="image" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-sm-3">
                                        <div className="client-box">
                                            <div className="client-thumbnail">
                                                <img className="img-fluid" src="images/slides/Partner/23.png" alt="image" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-sm-3">
                                        <div className="client-box">
                                            <div className="client-thumbnail">
                                                <img className="img-fluid" src="images/slides/Partner/24.png" alt="image" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-sm-3">
                                        <div className="client-box">
                                            <div className="client-thumbnail">
                                                <img className="img-fluid" src="images/slides/Partner/25.png" alt="image" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-sm-3">
                                        <div className="client-box">
                                            <div className="client-thumbnail">
                                                <img className="img-fluid" src="images/slides/Partner/26.png" alt="image" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-sm-3">
                                        <div className="client-box">
                                            <div className="client-thumbnail">
                                                <img className="img-fluid" src="images/slides/Partner/27.png" alt="image" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-sm-3">
                                        <div className="client-box">
                                            <div className="client-thumbnail">
                                                <img className="img-fluid" src="images/slides/Partner/28.png" alt="image" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-sm-3">
                                        <div className="client-box">
                                            <div className="client-thumbnail">
                                                <img className="img-fluid" src="images/slides/Partner/29.png" alt="image" />
                                            </div>
                                        </div>
                                    </div>
                                  
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="container">
                        <div className="row">
                            
                        <div className="col-lg-12">
                        <br/><br/>
                                {/* section-title */}
                                {/* <div className="section-title"> */}
                                    {/* <div className="title-header"> */}
                                       {/* <br/> */}
                                        <h5 className="title text-center"> Our India Office in Mumbai is <span className="text-theme-SkinColor"> DG Shipping Approved</span> since 2018.<br/> We are holding the license <span className="text-theme-SkinColor"> RPSL-MUM-1001 </span> which was issued on 8th January <br/> 2024, valid until 8th January 2029.</h5>
                                    {/* </div> */}
                                   
                                {/* </div>section-title end */}
                                {/* <a className="ttm-btn ttm-btn-size-md ttm-btn-shape-rounded ttm-btn-style-border ttm-btn-color-dark"
                                    href={process.env.PUBLIC_URL + '/'}>Explore More Companies</a> */}
                            </div>
                        </div>
                    </div>
                </section>
                {/* client-section end */}
                 
             

                {/* fid-section */}
                {/* <section className="ttm-row fid-section bg-img4 bg-theme-DarkColor ttm-bg ttm-bgimage-yes text-theme-WhiteColor clearfix" style={{ backgroundImage: 'url("images/slides/1920x570+row-bgimage-4.png")' }}>
                    <div className="ttm-row-wrapper-bg-layer ttm-bg-layer bg-theme-DarkColor"></div>
                    <div className="container">
                       
                        <div className="row ttm-vertical_sep mt_lg-15">
                            <div className="col-lg-3 col-md-6 col-sm-6">
                              
                                <div className="ttm-fid inside ttm-fid-view-lefticon style3">
                                    <div className="ttm-fid-icon-wrapper">
                                        <i className="flaticon flaticon-headhunting"></i>
                                    </div>
                                    <div className="ttm-fid-contents">
                                        <h4><CountUp start={0} end={8705} duration={20} delay={2} /></h4>
                                        <h3 className="ttm-fid-title">Job seeker</h3>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-6 col-sm-6">
                               
                                <div className="ttm-fid inside ttm-fid-view-lefticon style3">
                                    <div className="ttm-fid-icon-wrapper">
                                        <i className="flaticon flaticon-technical-support"></i>
                                    </div>
                                    <div className="ttm-fid-contents">
                                        <h4><CountUp start={0} end={480} duration={20} delay={2} /></h4>
                                        <h3 className="ttm-fid-title">Areas of expertise</h3>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-6 col-sm-6">
                               
                                <div className="ttm-fid inside ttm-fid-view-lefticon style3">
                                    <div className="ttm-fid-icon-wrapper">
                                        <i className="flaticon flaticon-recruitment-4"></i>
                                    </div>
                                    <div className="ttm-fid-contents">
                                        <h4><CountUp start={0} end={6260} duration={20} delay={2} /></h4>
                                        <h3 className="ttm-fid-title">career hub</h3>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-6 col-sm-6">
                                
                                <div className="ttm-fid inside ttm-fid-view-lefticon style3">
                                    <div className="ttm-fid-icon-wrapper">
                                        <i className="flaticon flaticon-recruitment-3"></i>
                                    </div>
                                    <div className="ttm-fid-contents">
                                        <h4><CountUp start={0} end={9774} duration={20} delay={2} /></h4>
                                        <h3 className="ttm-fid-title">Larger Community</h3>
                                    </div>
                                </div>
                            </div>
                        </div>
                       
                        <div className="ttm-horizontal_sep width-100 pt-60 mt-60 mt-lg-40"></div>
                        <div className="row align-items-center mb_35 mb-md-0">
                            <div className="col-lg-8 col-md-8">
                              
                                <div className="section-title">
                                    <div className="title-header">
                                        <h3>More than 50,000 <span className="text-theme-SkinColor">happy client</span></h3>
                                        <h2 className="title"><span className="fw-normal">Recruites</span> Let’s To Work Together Ready To Work
                                            <span className="fw-normal"> With Us.</span></h2>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-4">
                                <div className="d-flex align-items-center justify-content-md-end">
                                    <h6 className="font-weight-bold mb-0 mr-15">Watch Video!</h6>
                                    <a className=" d-flex">
                                        <span className="mb-0 p-10 ttm-icon ttm-icon_element-fill ttm-icon_element-color-skincolor ttm-icon_element-size-xs ttm-icon_element-style-rounded margin_right10 margin_bottom0">
                                            <Video className="fa fa-play" />
                                        </span>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </section> */}
                {/* fid-section end */}


                {/* about-section */}
            
                {/* <section className="ttm-row about-section clearfix"></section> */}
                {/* about-section end */}


               {/* services-section */}
            {/* <section className="ttm-row services-section clearfix"> */}
                
 {/* google_map */}
  <div className="section-title title-style-center_text">
                                <div className="title-header">
                                <h2>Explore <span className="text-theme-SkinColor">Global Manpower</span> Delivery</h2>
                                {/* <h2 className="title">Countries We Serve</h2> */}
                                </div>
                            </div>
 <div id='google_map' className='google_map'>
          <div className='map_container clearfix'>
            <div id='map'>
              <iframe src='https://www.google.com/maps/d/embed?mid=17GH0HrTDEvvjl16nvj6HZm2BjkCI5Bo&ehbc=2E312F&noprof=1' width='100%' height='550'></iframe>
            </div>
          </div>
        </div>
        {/* google_map end */}
                {/* <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="section-title title-style-center_text">
                                <div className="title-header">
                                <h2>Explore <span className="text-theme-SkinColor">Global Manpower</span> Delivery</h2>
                              
                                </div>
                            </div>
                        </div>
                    </div> */}
                    {/* row */}
                   
                    {/* <div className="row">
                        <div className="col-lg-12">
                            <div className="pt-30 m-auto text-center">
                                <a className="ttm-btn ttm-btn-size-md ttm-btn-shape-rounded ttm-btn-style-fill ttm-btn-color-skincolor" 
                                href={process.env.PUBLIC_URL + '/job_list'}>More Jobs By Category</a>
                            </div>
                        </div>
                    </div> */}
                {/* </div> */}
            {/* </section> */}
            {/* services-section end */}


                <Footer />

            </div>
        )
    }
}


export default Home2;