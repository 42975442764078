import React, { useState } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const ClientSection = ({ sliderElements,title,subtitle,bgImage }) => {
    const [currentSlide, setCurrentSlide] = useState(0);

    const handleAfterChange = (index) => {
        console.log("after change", index);
        setCurrentSlide(index);
    };

    const settings = {
        beforeChange: function (currentSlide, nextSlide) {
            console.log("before change", currentSlide, nextSlide);
        },
        afterChange: handleAfterChange,
        dots: false,
        arrows: true,
        autoplay: true,
        infinite: true,
        speed: 1000,
        slidesToScroll: 1,
        slidesToShow: 1,
        fade: true
    };

    return (
        <>
            {/* padding_zero-section */}
            <section className="ttm-row padding_zero-section bg-theme-DarkColor bg-layer-equal-height mb-100 mb-lg-0 clearfix">
                <div className="container">
                    {/* row */}
                    <div className="row">
                        <div className="col-lg-6">
                            {/* col-img-img-four */}
                            <div className="col-bg-img-four ttm-col-bgimage-yes ttm-bg mt-100 mt-lg-60 mr-30 mr-lg-0 border border-15
                                border-white p-15 h-100">
                                <div className="ttm-col-wrapper-bg-layer ttm-bg-layer" style={{ backgroundImage: `url(${bgImage})` }}></div>
                                <div className="layer-content"></div>
                                <img src={bgImage} className="img-fluid col-bg-img-res" alt="bgimage" />
                            </div>
                          
                            {/* col-img-bg-img-four end */}
                        </div>
                        <div className="col-lg-6">
                            <div className="pt-140 pt-lg-50 pb-100 pb-lg-60">
                                {/* section title */}
                                <div className="section-title">
                                    <div className="title-header">
                                        {/* <h3>Happy <span className="text-theme-SkinColor">Clients</span></h3>
                                        <h2 className="title">Listen Our Clients Stories</h2> */}
                                        <h3>{title}</h3>
                                        <h2 className='title'>{subtitle}</h2>
                                    </div>
                                </div>{/* section title end */}
                                <div className="position-relative">
                                    {/* <Slider className="slick_slider slick-arrows-style3" {...settings}>
                                        {sliderElements.map((element) => (
                                            <div key={element.id}>
                                                {element.description}
                                            </div>
                                        ))}
                                    </Slider> */}
                                      <Slider className="slick_slider " {...settings}>
                                        {sliderElements.map((element) => (
                                            <div key={element.id}>
                                                {element.description}
                                            </div>
                                        ))}
                                    </Slider>
                                    <div className="slick_slider_countable">
                                        <span className="currentSlide">{currentSlide + 1}</span>
                                        <span> / </span>
                                        <span className="totalSlide">{sliderElements.length}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* padding_zero-section end */}
        </>
    );
};

export default ClientSection;
