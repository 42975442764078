
import React from "react";
const ServicesDetail3 = () =>
{
    return(
        <>
          <div className="col-lg-8 content-area order-lg-2">
                                {/* ttm-service-single-content-area */}
                                <div className="ttm-service-single-content-area">
                                    <div className="ttm_single_image-wrapper">
                                        <img className="img-fluid" src="images/ServicesDetail/4.png" alt="services-img" />
                                    </div>
                                    <div className="ttm-service-description mt-40">
                                        <h3 className="mb-20">Description For Our Service</h3>
                                        <p>Providing skilled manpower for construction projects, both offshore and onshore, is essential for the successful execution and completion of various infrastructure and development initiatives. Here's how Atlas ensures a reliable and competent workforce for construction services</p>
                                        <div className="row">
                                            <div className="col-lg-6 col-md-6 col-sm-6">
                                                {/* featured-icon-box */} 
                                                <div className="featured-icon-box icon-align-before-content icon-ver_align-top mr-50 mr-md-0 mt-20">
                                                    <div className="featured-icon">
                                                        <div className="ttm-icon ttm-icon_element-onlytxt ttm-icon_element-color-skincolor ttm-icon_element-size-lg">
                                                            <i className="flaticon-research"></i>
                                                        </div>
                                                    </div>
                                                    <div className="featured-content">
                                                        <div className="featured-title">
                                                            <h3>Offshore Construction</h3>
                                                        </div>
                                                        <div className="featured-desc">
                                                            <p>For offshore construction projects, such as the building of offshore platforms and installations, Atlas specializes in providing skilled manpower for a range of roles. This includes structural welders, pipefitters, riggers, scaffolders, electricians, crane operators, and project managers. Our recruitment team selects candidates with relevant experience in offshore construction, including knowledge of marine operations, safety procedures, and industry regulations.</p>
                                                        </div>
                                                    </div>
                                                </div>{/* featured-icon-box end */}
                                            </div>
                                            <div className="col-lg-6 col-md-6 col-sm-6">
                                                {/* featured-icon-box */}
                                                <div className="featured-icon-box icon-align-before-content icon-ver_align-top mr-50 mr-md-0 mt-20">
                                                    <div className="featured-icon">
                                                        <div className="ttm-icon ttm-icon_element-onlytxt ttm-icon_element-color-skincolor ttm-icon_element-size-lg">
                                                            <i className="flaticon-healthcare"></i>
                                                        </div>
                                                    </div>
                                                    <div className="featured-content">
                                                        <div className="featured-title">
                                                            <h3>Technical Expertise</h3>
                                                        </div>
                                                        <div className="featured-desc">
                                                            <p>Atlas recruits construction professionals with technical expertise in various disciplines, including civil engineering, structural engineering, mechanical engineering, electrical engineering, and architectural design. Our workforce includes individuals with specialized skills in construction methods, building codes, material specifications, and project management techniques, enabling us to deliver high-quality construction services for both offshore and onshore projects.</p>
                                                        </div>
                                                    </div>
                                                </div>{/* featured-icon-box end */}
                                            </div>
                                            <div className="col-lg-6 col-md-6 col-sm-6">
                                                {/* featured-icon-box */}
                                                <div className="featured-icon-box icon-align-before-content icon-ver_align-top mr-50 mr-md-0 mt-20">
                                                    <div className="featured-icon">
                                                        <div className="ttm-icon ttm-icon_element-onlytxt ttm-icon_element-color-skincolor ttm-icon_element-size-lg">
                                                            <i className="flaticon-conveyor"></i>
                                                        </div>
                                                    </div>
                                                    <div className="featured-content">
                                                        <div className="featured-title">
                                                            <h3>Safety Training</h3>
                                                        </div>
                                                        <div className="featured-desc">
                                                            <p>Safety is a top priority in construction projects, whether offshore or onshore. Atlas ensures that all construction personnel undergo rigorous safety training to adhere to industry standards and regulations. This includes training on hazard identification, risk assessment, personal protective equipment (PPE) usage, emergency procedures, and site-specific safety protocols.</p>
                                                        </div>
                                                    </div>
                                                </div>{/* featured-icon-box end */}
                                            </div>
                                            <div className="col-lg-6 col-md-6 col-sm-6">
                                                {/* featured-icon-box */}
                                                <div className="featured-icon-box icon-align-before-content icon-ver_align-top mr-50 mr-md-0 mt-20">
                                                    <div className="featured-icon">
                                                        <div className="ttm-icon ttm-icon_element-onlytxt ttm-icon_element-color-skincolor ttm-icon_element-size-lg">
                                                            <i className="flaticon-human-resources-3"></i>
                                                        </div>
                                                    </div>
                                                    <div className="featured-content">
                                                        <div className="featured-title">
                                                            <h3>Project Management</h3>
                                                        </div>
                                                        <div className="featured-desc">
                                                            <p>Atlas provides experienced project managers and construction supervisors to oversee and coordinate construction activities, ensuring that projects are completed on time, within budget, and according to specifications. Our project management team ensures effective communication, resource allocation, quality control, and risk management throughout the construction process.</p>
                                                        </div>
                                                    </div>
                                                </div>{/* featured-icon-box end */}
                                            </div>
                                            <div className="col-lg-6 col-md-6 col-sm-6">
                                                {/* featured-icon-box */}
                                                <div className="featured-icon-box icon-align-before-content icon-ver_align-top mr-50 mr-md-0 mt-20">
                                                    <div className="featured-icon">
                                                        <div className="ttm-icon ttm-icon_element-onlytxt ttm-icon_element-color-skincolor ttm-icon_element-size-lg">
                                                            <i className="flaticon-human-resources-3"></i>
                                                        </div>
                                                    </div>
                                                    <div className="featured-content">
                                                        <div className="featured-title">
                                                            <h3>Manpower Planning</h3>
                                                        </div>
                                                        <div className="featured-desc">
                                                            <p>Atlas conducts comprehensive manpower planning to meet the staffing requirements of construction projects, both offshore and onshore. This includes forecasting manpower needs, identifying skill requirements, and recruiting qualified personnel to meet project deadlines and objectives.</p>
                                                        </div>
                                                    </div>
                                                </div>{/* featured-icon-box end */}
                                            </div>
                                            <div className="col-lg-6 col-md-6 col-sm-6">
                                                {/* featured-icon-box */}
                                                <div className="featured-icon-box icon-align-before-content icon-ver_align-top mr-50 mr-md-0 mt-20">
                                                    <div className="featured-icon">
                                                        <div className="ttm-icon ttm-icon_element-onlytxt ttm-icon_element-color-skincolor ttm-icon_element-size-lg">
                                                            <i className="flaticon-human-resources-3"></i>
                                                        </div>
                                                    </div>
                                                    <div className="featured-content">
                                                        <div className="featured-title">
                                                            <h3>Continuous Development</h3>
                                                        </div>
                                                        <div className="featured-desc">
                                                            <p>We invest in the continuous training and development of our construction manpower to enhance their skills, knowledge, and capabilities. This includes providing access to training programs, certifications, and professional development opportunities to ensure that our workforce remains up-to-date with industry trends, technologies, and best practices.</p>
                                                        </div>
                                                    </div>
                                                </div>{/* featured-icon-box end */}
                                            </div>
                                          
                                        </div>
                                        <p>By prioritizing recruitment, training, safety, project management, and continuous development, Atlas ensures a skilled and reliable workforce for construction projects, whether offshore or onshore. Our commitment to excellence and customer satisfaction drives us to deliver superior construction services that meet the highest standards of quality, safety, and efficiency.</p>
                                        <div className="ttm-horizontal_sep width-100 mt-25 mb-35"></div>
                                        {/* <div className="row">
                                            <div className="col-md-6">
                                                <h4>The Best Aquarist You Can Make is In Your Self</h4>
                                                <p>Lorem ipsum dolor sit amet, consectetur adipis ing elit, sed do eiusmod tempor incidi.</p>
                                                <ul className="ttm-list ttm-list-style-square ttm-list-icon-color-skincolor mb-30">
                                                    <li>
                                                        <div className="ttm-list-li-content">Lorem ipsum dolor sit amet, consectetur adipi</div>
                                                    </li>
                                                    <li>
                                                        <div className="ttm-list-li-content">There are many variations of passages of Lorem</div>
                                                    </li>
                                                    <li>
                                                        <div className="ttm-list-li-content">The standard chunk of Lorem Ipsum used since</div>
                                                    </li>
                                                    <li>
                                                        <div className="ttm-list-li-content">Contrary to popular belief, Lorem Ipsum</div>
                                                    </li>
                                                </ul>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="ttm_single_image-wrapper mb-30">
                                                    <img className="img-fluid" src="https://via.placeholder.com/1200x800?text=1200x800+services-02-1200x800.jpg" alt="services-img" />
                                                </div>
                                            </div>
                                            <div className="col-lg-12">
                                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt utlabore
                                                    et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus com </p>
                                            </div>
                                        </div> */}
                                    </div>
                                </div>
                                {/* ttm-service-single-content-are end */}
                            </div>
        </>
    )

}

export default ServicesDetail3;