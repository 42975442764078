import React,{useState,useEffect} from "react";
import axios from "axios";
const GetInTouch = () =>
{
    const [formData,setFormData] = useState ({
        name: '',
        email: '',
        phone: '',
        subject: '',
        message: '',
    });

    const handleChange = (e) =>
    {
setFormData({...formData,[e.target.name]:e.target.value});
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
    console.log(formData);
        try {
          // const response = await axios.post("http://localhost:4000/sendContactDetail", formData);
          // const response = await axios.post("https://atlas-back-end.vercel.app/sendContactDetail", formData);
          const response = await axios.post("https://node.atlasindia.co/sendContactDetail", formData);
          console.log("Form data sent successfully:", response.data);
         setFormData({
            name: '',
            email: '',
            phone: '',
            subject: '',
            message: '',
         });
          alert('Message sent successfully!');
          window.location.reload();
          // Optionally, you can display a success message or redirect the user
        } catch (error) {
          console.error("Error sending form data:", error);
          // Optionally, you can display an error message to the user
        }
      };
return(
    <>
   
             
              <div className='col-lg-6'>
                <div className='ttm-col-bgcolor-yes ttm-bg bg-theme-WhiteColor z-index-2 p-40 p-lg-30 mt-lg-30'>
                  <div className='ttm-col-wrapper-bg-layer ttm-bg-layer'></div>
                  <div className='layer-content'>
                    <form id='contact_form' className='contact_form wrap-form' onSubmit={handleSubmit}>
                      <div className='row ttm-boxes-spacing-10px'>
                        <div className='col-md-12 ttm-box-col-wrapper'>
                          <label>
                            <input
                              name='name'
                              type='text'
                              placeholder='Your Name'
                              onChange={handleChange}
                              required='required' />
                          </label>
                        </div>
                        <div className='col-md-6 ttm-box-col-wrapper'>
                          <label>
                            <input
                              name='email'
                              type='text'
                              placeholder='Your Email'
                              onChange={handleChange}
                              required='required' />
                          </label>
                        </div>
                        <div className='col-md-6 ttm-box-col-wrapper'>
                          <label>
                            <input
                              name='subject'
                              type='text'
                              placeholder='Subject'
                              onChange={handleChange}
                              required='required' />
                          </label>
                        </div>
                        <div className='col-md-12 ttm-box-col-wrapper'>
                          <label>
                            <input
                              name='phone'
                              type='text'
                              placeholder='Phone Number'
                              onChange={handleChange}
                              required='required' />
                          </label>
                        </div>
                        <div className='col-md-12 ttm-box-col-wrapper'>
                          <label>
                            <textarea
                              name='message'
                              rows='7'
                              placeholder='Message'
                              onChange={handleChange}
                              required='required'></textarea>
                          </label>
                        </div>
                      </div>
                      <button className='submit ttm-btn ttm-btn-size-md ttm-btn-shape-rounded ttm-btn-style-fill
                                                                                                                          ttm-btn-color-skincolor w-100' type='submit'>
                        send a message!
                      </button>
                    </form>
                  </div>
                </div>
              </div>
            
    </>
)
}

export default GetInTouch;