
import React from "react";
const ServicesDetail4 = () =>
{
    return(
        <>
          <div className="col-lg-8 content-area order-lg-2">
                                {/* ttm-service-single-content-area */}
                                <div className="ttm-service-single-content-area">
                                    <div className="ttm_single_image-wrapper">
                                        <img className="img-fluid" src="images/ServicesDetail/5.png" alt="services-img" />
                                    </div>
                                    <div className="ttm-service-description mt-40">
                                        <h3 className="mb-20">Description For Our Service</h3>
                                        <p>Atlas specializes in providing manpower services for both white-collar and blue-collar sectors, catering to diverse industries and job roles. Here's how we ensure a reliable and competent workforce for our clients</p>
                                        <div className="row">
                                            <div className="col-lg-6 col-md-6 col-sm-6">
                                                {/* featured-icon-box */} 
                                                <div className="featured-icon-box icon-align-before-content icon-ver_align-top mr-50 mr-md-0 mt-20">
                                                    <div className="featured-icon">
                                                        <div className="ttm-icon ttm-icon_element-onlytxt ttm-icon_element-color-skincolor ttm-icon_element-size-lg">
                                                            <i className="flaticon-research"></i>
                                                        </div>
                                                    </div>
                                                    <div className="featured-content">
                                                        <div className="featured-title">
                                                            <h3>White-Collar Manpower Services</h3>
                                                        </div>
                                                        <div className="featured-desc">
                                                            <p>We offer recruitment solutions for professional roles that typically require formal education, specialized skills, and professional qualifications. This includes positions such as engineers, project managers, architects, accountants, IT professionals, sales and marketing executives, human resource professionals, and administrative staff. Our recruitment process focuses on identifying candidates with the right expertise, experience, and cultural fit for our clients' organizations.</p>
                                                        </div>
                                                    </div>
                                                </div>{/* featured-icon-box end */}
                                            </div>
                                            <div className="col-lg-6 col-md-6 col-sm-6">
                                                {/* featured-icon-box */}
                                                <div className="featured-icon-box icon-align-before-content icon-ver_align-top mr-50 mr-md-0 mt-20">
                                                    <div className="featured-icon">
                                                        <div className="ttm-icon ttm-icon_element-onlytxt ttm-icon_element-color-skincolor ttm-icon_element-size-lg">
                                                            <i className="flaticon-healthcare"></i>
                                                        </div>
                                                    </div>
                                                    <div className="featured-content">
                                                        <div className="featured-title">
                                                            <h3>Blue-Collar Manpower Services</h3>
                                                        </div>
                                                        <div className="featured-desc">
                                                            <p> In addition to white-collar roles, Atlas provides manpower services for blue-collar positions that involve manual labor and technical skills. This includes trades such as welders, electricians, plumbers, carpenters, technicians, mechanics, drivers, machine operators, and general laborers. We recruit skilled and experienced blue-collar workers who are proficient in their respective trades and capable of meeting the demands of various industries, including construction, manufacturing, logistics, and maintenance.</p>
                                                        </div>
                                                    </div>
                                                </div>{/* featured-icon-box end */}
                                            </div>
                                            <div className="col-lg-6 col-md-6 col-sm-6">
                                                {/* featured-icon-box */}
                                                <div className="featured-icon-box icon-align-before-content icon-ver_align-top mr-50 mr-md-0 mt-20">
                                                    <div className="featured-icon">
                                                        <div className="ttm-icon ttm-icon_element-onlytxt ttm-icon_element-color-skincolor ttm-icon_element-size-lg">
                                                            <i className="flaticon-conveyor"></i>
                                                        </div>
                                                    </div>
                                                    <div className="featured-content">
                                                        <div className="featured-title">
                                                            <h3>Customized Solutions</h3>
                                                        </div>
                                                        <div className="featured-desc">
                                                            <p>Atlas offers customized manpower solutions tailored to the specific needs and requirements of our clients. Whether they need temporary workers for short-term projects, permanent employees for long-term positions, or contract workers for specialized tasks, we provide flexible staffing solutions to meet their workforce demands. Our team works closely with clients to understand their staffing needs, budget constraints, and project timelines, ensuring that we deliver the right manpower solutions that align with their business objectives.</p>
                                                        </div>
                                                    </div>
                                                </div>{/* featured-icon-box end */}
                                            </div>
                                            <div className="col-lg-6 col-md-6 col-sm-6">
                                                {/* featured-icon-box */}
                                                <div className="featured-icon-box icon-align-before-content icon-ver_align-top mr-50 mr-md-0 mt-20">
                                                    <div className="featured-icon">
                                                        <div className="ttm-icon ttm-icon_element-onlytxt ttm-icon_element-color-skincolor ttm-icon_element-size-lg">
                                                            <i className="flaticon-human-resources-3"></i>
                                                        </div>
                                                    </div>
                                                    <div className="featured-content">
                                                        <div className="featured-title">
                                                            <h3>Compliance and Quality Assurance</h3>
                                                        </div>
                                                        <div className="featured-desc">
                                                            <p>We adhere to strict compliance standards and quality assurance protocols to ensure that all our manpower services meet industry regulations and client expectations. This includes verifying the qualifications, certifications, and background checks of candidates, ensuring compliance with labor laws and regulations, and maintaining high standards of safety, professionalism, and performance on the job.</p>
                                                        </div>
                                                    </div>
                                                </div>{/* featured-icon-box end */}
                                            </div>
                                            <div className="col-lg-6 col-md-6 col-sm-6">
                                                {/* featured-icon-box */}
                                                <div className="featured-icon-box icon-align-before-content icon-ver_align-top mr-50 mr-md-0 mt-20">
                                                    <div className="featured-icon">
                                                        <div className="ttm-icon ttm-icon_element-onlytxt ttm-icon_element-color-skincolor ttm-icon_element-size-lg">
                                                            <i className="flaticon-human-resources-3"></i>
                                                        </div>
                                                    </div>
                                                    <div className="featured-content">
                                                        <div className="featured-title">
                                                            <h3>Continuous Support and Communication</h3>
                                                        </div>
                                                        <div className="featured-desc">
                                                            <p>Atlas provides ongoing support and communication to both clients and candidates throughout the recruitment process and beyond. We strive to build long-term relationships with our clients by offering exceptional customer service, addressing any concerns or issues promptly, and providing guidance and assistance whenever needed. Similarly, we offer career guidance, training opportunities, and professional development resources to our candidates to help them succeed in their roles and advance their careers.</p>
                                                        </div>
                                                    </div>
                                                </div>{/* featured-icon-box end */}
                                            </div>
                                            
                                          
                                        </div>
                                        <p>By offering comprehensive manpower services for both white-collar and blue-collar sectors, Atlas serves as a trusted partner for organizations seeking to build a skilled and reliable workforce. Our commitment to excellence, integrity, and customer satisfaction drives us to deliver superior staffing solutions that meet the evolving needs of our clients and contribute to their success.</p>
                                        <div className="ttm-horizontal_sep width-100 mt-25 mb-35"></div>
                                        {/* <div className="row">
                                            <div className="col-md-6">
                                                <h4>The Best Aquarist You Can Make is In Your Self</h4>
                                                <p>Lorem ipsum dolor sit amet, consectetur adipis ing elit, sed do eiusmod tempor incidi.</p>
                                                <ul className="ttm-list ttm-list-style-square ttm-list-icon-color-skincolor mb-30">
                                                    <li>
                                                        <div className="ttm-list-li-content">Lorem ipsum dolor sit amet, consectetur adipi</div>
                                                    </li>
                                                    <li>
                                                        <div className="ttm-list-li-content">There are many variations of passages of Lorem</div>
                                                    </li>
                                                    <li>
                                                        <div className="ttm-list-li-content">The standard chunk of Lorem Ipsum used since</div>
                                                    </li>
                                                    <li>
                                                        <div className="ttm-list-li-content">Contrary to popular belief, Lorem Ipsum</div>
                                                    </li>
                                                </ul>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="ttm_single_image-wrapper mb-30">
                                                    <img className="img-fluid" src="https://via.placeholder.com/1200x800?text=1200x800+services-02-1200x800.jpg" alt="services-img" />
                                                </div>
                                            </div>
                                            <div className="col-lg-12">
                                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt utlabore
                                                    et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus com </p>
                                            </div>
                                        </div> */}
                                    </div>
                                </div>
                                {/* ttm-service-single-content-are end */}
                            </div>
        </>
    )

}

export default ServicesDetail4;