import React from "react";
const OurStory = () =>
{
    return(
        <>
            <section className="ttm-row about-section clearfix">
                    <div className="container">
                       
                        <div className="row align-items-center">
                        <div className='row'>
              <div className='col-lg-12'>
                {/* section title */}
                <div className='section-title title-style-center_text'>
                  <div className='title-header'>
                    <h3>our <span className='text-theme-SkinColor'>Story!</span></h3>
                    <h2 className='title'>Streamlining Resources for Business Success.</h2>
                  </div>
                </div>
                {/* section title end */}
              </div>
            </div> 
                            {/* <div className="col-lg-6 col-md-8">
                               
                                <div className="section-title">
                                    <div className="title-header">
                                        <h3>Our  <span className="text-theme-SkinColor"> Story! </span></h3>
                                        <h2 className="title">Streamlining Resources For Business Success.</h2>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6 col-md-4">
                                <a className="ttm-btn ttm-btn-size-md ttm-btn-shape-rounded ttm-btn-style-border ttm-btn-color-dark mb-15"
                                    href={process.env.PUBLIC_URL + '/'}>Get Started</a>
                                    
                            </div> */}
                            <div className="col-lg-6 col-md-10 col-11 m-auto">
                                <div className="bg-theme-GreyColor ttm-col-bgcolor-yes ttm-bg spacing-2 z-index_1">
                                    <div className="ttm-col-wrapper-bg-layer ttm-bg-layer"></div>
                                    <div className="layer-content">
                                    </div>
                                </div>
                                
                                <div className="ttm_single_image-wrapper">
                                    <img className="img-fluid" src="images/slides/ourStory.png" alt="single_03" />
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="featuredbox-number pr-30 pr-lg-0 pb-lg-50 pt-md-20">
                                   
                                    <div className="featured-icon-box icon-align-before-content icon-ver_align-top style4">
                                        <div className="featured-icon">
                                            <div className="ttm-icon ttm-icon_element-fill ttm-icon_element-color-grey ttm-icon_element-size-md ttm-icon_element-style-rounded">
                                                <i className="ttm-num ti-info"></i>
                                            </div>
                                        </div>
                                        <div className="featured-content ttm-bgcolor-grey">
                                            <div className="featured-title">
                                                <h3>2012 - Established in India</h3>
                                            </div>
                                            <div className="featured-desc">
                                                 {/* <p>Create a list of needs before you create a job posting. It may seem easy to identify a hiring need </p> */}
                                            </div>
                                        </div>
                                    </div>
                                   
                                    <div className="featured-icon-box icon-align-before-content icon-ver_align-top style4">
                                        <div className="featured-icon">
                                            <div className="ttm-icon ttm-icon_element-fill ttm-icon_element-color-grey ttm-icon_element-size-md ttm-icon_element-style-rounded">
                                                <i className="ttm-num ti-info"></i>
                                            </div>
                                        </div>
                                        <div className="featured-content ttm-bgcolor-grey">
                                            <div className="featured-title">
                                                <h3>2017 - We commenced operations in Singapore, broadening our global footprint</h3>
                                            </div>
                                            <div className="featured-desc">
                                                {/* <p>Save time and energy by creating the best recruitment plan. To get the word out about the job</p> */}
                                            </div>
                                        </div>
                                    </div>
                                   
                                    <div className="featured-icon-box icon-align-before-content icon-ver_align-top style4">
                                        <div className="featured-icon">
                                            <div className="ttm-icon ttm-icon_element-fill ttm-icon_element-color-grey ttm-icon_element-size-md ttm-icon_element-style-rounded">
                                                <i className="ttm-num ti-info"></i>
                                            </div>
                                        </div>
                                        <div className="featured-content ttm-bgcolor-grey">
                                            <div className="featured-title">
                                                <h3>2018 - Established presence in Qatar and initiated operations in the Al-Shaheen Oil Field. </h3>
                                            </div>
                                            <div className="featured-desc">
                                                {/* <p>You will need to maintain timely communication or they will quickly move on to other opportunities</p> */}
                                            </div>
                                        </div>
                                    </div>
                                   
                                    <div className="featured-icon-box icon-align-before-content icon-ver_align-top style4">
                                        <div className="featured-icon">
                                            <div className="ttm-icon ttm-icon_element-fill ttm-icon_element-color-grey ttm-icon_element-size-md ttm-icon_element-style-rounded">
                                                <i className="ttm-num ti-info"></i>
                                            </div>
                                        </div>
                                        <div className="featured-content ttm-bgcolor-grey">
                                            <div className="featured-title">
                                                <h3>2022 - Originating in the UAE, we swiftly commenced prosperous operations in Dubai and expanded our reach by initiating operations with Qatar Gas.</h3>
                                            </div>
                                            <div className="featured-desc">
                                                {/* <p>Create a list of needs before you create a job posting. It may seem easy to identify a hiring need</p> */}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="featured-icon-box icon-align-before-content icon-ver_align-top style4">
                                        <div className="featured-icon">
                                            <div className="ttm-icon ttm-icon_element-fill ttm-icon_element-color-grey ttm-icon_element-size-md ttm-icon_element-style-rounded">
                                                <i className="ttm-num ti-info"></i>
                                            </div>
                                        </div>
                                        <div className="featured-content ttm-bgcolor-grey">
                                            <div className="featured-title">
                                                <h3>2024 - We have effectively onboarded over 5000 personnel and the numbers continue to grow.</h3>
                                            </div>
                                            <div className="featured-desc">
                                                {/* <p>Create a list of needs before you create a job posting. It may seem easy to identify a hiring need</p> */}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                          
                        </div>
                    </div>
                </section>
        </>
    )
}
export default OurStory;