
import React from "react";
const ServicesDetail2 = () =>
{
    return(
        <>
          <div className="col-lg-8 content-area order-lg-2">
                                {/* ttm-service-single-content-area */}
                                <div className="ttm-service-single-content-area">
                                    <div className="ttm_single_image-wrapper">
                                        <img className="img-fluid" src="images/ServicesDetail/3.png" alt="services-img" />
                                    </div>
                                    <div className="ttm-service-description mt-40">
                                        <h3 className="mb-20">Description For Our Service</h3>
                                        <p>Providing skilled manpower for dredging operations is crucial for the success and efficiency of projects in the maritime and construction industries. Here's how Atlas ensures a reliable and competent workforce for dredging services</p>
                                        <div className="row">
                                            <div className="col-lg-6 col-md-6 col-sm-6">
                                                {/* featured-icon-box */} 
                                                <div className="featured-icon-box icon-align-before-content icon-ver_align-top mr-50 mr-md-0 mt-20">
                                                    <div className="featured-icon">
                                                        <div className="ttm-icon ttm-icon_element-onlytxt ttm-icon_element-color-skincolor ttm-icon_element-size-lg">
                                                            <i className="flaticon-research"></i>
                                                        </div>
                                                    </div>
                                                    <div className="featured-content">
                                                        <div className="featured-title">
                                                            <h3>Recruitment Expertise</h3>
                                                        </div>
                                                        <div className="featured-desc">
                                                            <p>  Atlas specializes in sourcing and recruiting highly skilled personnel with expertise in various aspects of dredging operations. Our recruitment team identifies candidates with relevant qualifications, certifications, and experience to meet the specific needs of each dredging project.</p>
                                                        </div>
                                                    </div>
                                                </div>{/* featured-icon-box end */}
                                            </div>
                                            <div className="col-lg-6 col-md-6 col-sm-6">
                                                {/* featured-icon-box */}
                                                <div className="featured-icon-box icon-align-before-content icon-ver_align-top mr-50 mr-md-0 mt-20">
                                                    <div className="featured-icon">
                                                        <div className="ttm-icon ttm-icon_element-onlytxt ttm-icon_element-color-skincolor ttm-icon_element-size-lg">
                                                            <i className="flaticon-healthcare"></i>
                                                        </div>
                                                    </div>
                                                    <div className="featured-content">
                                                        <div className="featured-title">
                                                            <h3>Dredging Crew</h3>
                                                        </div>
                                                        <div className="featured-desc">
                                                            <p>We prioritize hiring qualified dredging crew for roles such as dredge masters, dredge operators, deckhands, and engineers. This includes ensuring that crew members possess the necessary maritime qualifications, dredging certifications, and proficiency in operating dredging equipment.</p>
                                                        </div>
                                                    </div>
                                                </div>{/* featured-icon-box end */}
                                            </div>
                                            <div className="col-lg-6 col-md-6 col-sm-6">
                                                {/* featured-icon-box */}
                                                <div className="featured-icon-box icon-align-before-content icon-ver_align-top mr-50 mr-md-0 mt-20">
                                                    <div className="featured-icon">
                                                        <div className="ttm-icon ttm-icon_element-onlytxt ttm-icon_element-color-skincolor ttm-icon_element-size-lg">
                                                            <i className="flaticon-conveyor"></i>
                                                        </div>
                                                    </div>
                                                    <div className="featured-content">
                                                        <div className="featured-title">
                                                            <h3>Technical Expertise</h3>
                                                        </div>
                                                        <div className="featured-desc">
                                                            <p>Atlas recruits dredging professionals with technical expertise in hydraulic dredging, mechanical dredging, cutter suction dredging, and other dredging techniques. This includes personnel with experience in operating and maintaining various types of dredging equipment, such as dredgers, pumps, and pipelines.</p>
                                                        </div>
                                                    </div>
                                                </div>{/* featured-icon-box end */}
                                            </div>
                                            <div className="col-lg-6 col-md-6 col-sm-6">
                                                {/* featured-icon-box */}
                                                <div className="featured-icon-box icon-align-before-content icon-ver_align-top mr-50 mr-md-0 mt-20">
                                                    <div className="featured-icon">
                                                        <div className="ttm-icon ttm-icon_element-onlytxt ttm-icon_element-color-skincolor ttm-icon_element-size-lg">
                                                            <i className="flaticon-human-resources-3"></i>
                                                        </div>
                                                    </div>
                                                    <div className="featured-content">
                                                        <div className="featured-title">
                                                            <h3>Safety Training</h3>
                                                        </div>
                                                        <div className="featured-desc">
                                                            <p>Safety is paramount in dredging operations. Atlas ensures that all dredging personnel undergo rigorous safety training to adhere to industry regulations and best practices. This includes training on dredging safety procedures, hazard recognition, confined space entry, and personal protective equipment (PPE) usage.</p>
                                                        </div>
                                                    </div>
                                                </div>{/* featured-icon-box end */}
                                            </div>
                                            <div className="col-lg-6 col-md-6 col-sm-6">
                                                {/* featured-icon-box */}
                                                <div className="featured-icon-box icon-align-before-content icon-ver_align-top mr-50 mr-md-0 mt-20">
                                                    <div className="featured-icon">
                                                        <div className="ttm-icon ttm-icon_element-onlytxt ttm-icon_element-color-skincolor ttm-icon_element-size-lg">
                                                            <i className="flaticon-human-resources-3"></i>
                                                        </div>
                                                    </div>
                                                    <div className="featured-content">
                                                        <div className="featured-title">
                                                            <h3>Continuous Development</h3>
                                                        </div>
                                                        <div className="featured-desc">
                                                            <p>We invest in the continuous training and development of our dredging manpower to enhance their skills and keep them updated with the latest technologies and techniques. This includes technical training on dredging equipment operation, maintenance, troubleshooting, and environmental compliance.</p>
                                                        </div>
                                                    </div>
                                                </div>{/* featured-icon-box end */}
                                            </div>
                                            <div className="col-lg-6 col-md-6 col-sm-6">
                                                {/* featured-icon-box */}
                                                <div className="featured-icon-box icon-align-before-content icon-ver_align-top mr-50 mr-md-0 mt-20">
                                                    <div className="featured-icon">
                                                        <div className="ttm-icon ttm-icon_element-onlytxt ttm-icon_element-color-skincolor ttm-icon_element-size-lg">
                                                            <i className="flaticon-human-resources-3"></i>
                                                        </div>
                                                    </div>
                                                    <div className="featured-content">
                                                        <div className="featured-title">
                                                            <h3>Manpower Planning</h3>
                                                        </div>
                                                        <div className="featured-desc">
                                                            <p> Atlas conducts thorough manpower planning to anticipate staffing needs for dredging projects. This includes forecasting crew requirements, assessing skill gaps, and implementing recruitment strategies to ensure the availability of qualified personnel for various dredging operations.</p>
                                                        </div>
                                                    </div>
                                                </div>{/* featured-icon-box end */}
                                            </div>
                                            <div className="col-lg-6 col-md-6 col-sm-6">
                                                {/* featured-icon-box */}
                                                <div className="featured-icon-box icon-align-before-content icon-ver_align-top mr-50 mr-md-0 mt-20">
                                                    <div className="featured-icon">
                                                        <div className="ttm-icon ttm-icon_element-onlytxt ttm-icon_element-color-skincolor ttm-icon_element-size-lg">
                                                            <i className="flaticon-human-resources-3"></i>
                                                        </div>
                                                    </div>
                                                    <div className="featured-content">
                                                        <div className="featured-title">
                                                            <h3>Performance Monitoring</h3>
                                                        </div>
                                                        <div className="featured-desc">
                                                            <p>We monitor the performance of our dredging manpower closely to ensure productivity, quality, and adherence to safety protocols. This includes regular performance evaluations, feedback mechanisms, and corrective actions to address any issues or challenges that may arise during dredging operations.</p>
                                                        </div>
                                                    </div>
                                                </div>{/* featured-icon-box end */}
                                            </div>
                                        </div>
                                        <p>By prioritizing recruitment, training, safety, and performance monitoring, Atlas ensures a skilled and reliable workforce for dredging services, enabling successful and efficient dredging projects in the maritime and construction sectors.</p>
                                        <div className="ttm-horizontal_sep width-100 mt-25 mb-35"></div>
                                        <div className="row">
                                            <div className="col-md-6">
                                                <h4>Dredging</h4>
                                                <p>The role of dredging in the construction and upkeep of numerous maritime infrastructures cannot be overstated. Candidates with Atlas are presented with opportunities across a spectrum of roles within the Dredging & Port Construction industry, involving the use of various equipment types including hopper suction, cutter suction, dipper dredgers, and more. A career in this field offers challenging roles at all levels, along with abundant opportunities for professional growth and development.</p>
                                                <h4>How to get there?</h4>
                                                {/* <ul className="ttm-list ttm-list-style-square ttm-list-icon-color-skincolor mb-30">
                                                    <li>
                                                        <div className="ttm-list-li-content">Lorem ipsum dolor sit amet, consectetur adipi</div>
                                                    </li>
                                                    <li>
                                                        <div className="ttm-list-li-content">There are many variations of passages of Lorem</div>
                                                    </li>
                                                    <li>
                                                        <div className="ttm-list-li-content">The standard chunk of Lorem Ipsum used since</div>
                                                    </li>
                                                    <li>
                                                        <div className="ttm-list-li-content">Contrary to popular belief, Lorem Ipsum</div>
                                                    </li>
                                                </ul> */}
                                            </div>
                                            <div className="col-md-6">
                                                <div className="ttm_single_image-wrapper mb-30">
                                                    <img className="img-fluid" src="images/ServicesDetail/3.svg" alt="services-img" />
                                                </div>
                                            </div>
                                            <div className="col-lg-12">
                                                <p>The Dredging & Port Construction industry offers diverse options, including opportunities to work with self-propelled or stationary equipment, or to take on land-based positions. For roles involving self-propelled equipment, candidates typically require a nautical certificate (STCW 95), along with a relevant secondary vocational degree or higher. For positions involving stationary equipment, familiarity with working on water and machinery, as well as a background in dredging, are recommended. Experience in the dredging industry is essential for land-based roles.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* ttm-service-single-content-are end */}
                            </div>
        </>
    )

}

export default ServicesDetail2;