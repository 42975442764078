import React, { Component } from 'react'
import axios from 'axios';

export class Footer extends Component {

  constructor(props) {
    super(props);
    this.state = {
      formData: {
        email: '' // Initialize email to an empty string
      }
    };
  }

  handleChange = (event) => {
    const { name, value } = event.target;
    this.setState({
      formData: {
        ...this.state.formData,
        [name]: value
      }
    });
  };

  handleSubmit = async (e) => {
    e.preventDefault();
    const { formData } = this.state;
    try {
      const formDataToSend = new FormData();
      formDataToSend.append('email', formData.email);
  
      // console.log('Form Data to Send:', formDataToSend);
      // console.log('Email:', formData.email);
      console.log('Form Data to Send:', formData);
      console.log('Email:', formData.email);
      // const response = await axios.post('http://localhost:4000/SubscriptionMail/', formData);
       // const response = await axios.post('https://atlas-back-end.vercel.app/SubscriptionMail', formData);
      const response = await axios.post('https://node.atlasindia.co/SubscriptionMail', formData);
      console.log('Email:', formData.email);
      console.log('Response:', response);
  
      if (response.status === 200) {
        // Successful response
        this.setState({
          formData: {
            email: ''
          }
        });
        alert('Thank you for subscribing!');
      } else {
        // Handle error
        console.log("Error:", response.status);
        alert('An error occurred while subscribing. Please try again later.');
      }
    } catch (error) {
      console.error('Error sending message:', error);
      alert('An error occurred while sending the message. Please try again later.');
    }
  }
  
  render () {
    const { formData } = this.state;
    return (
      // <footer className='footer widget-footer bg-theme-DarkColor text-theme-WhiteColor clearfix'>
<footer className='footer widget-footer bg-theme-LightGreyColor text-theme-DarkColor clearfix'>
<div className='second-footer'>
          <div className='container'>
            <div className='row'>
              <div className='col-xs-12 col-sm-12 col-md-12 col-lg-4 widget-area'>
                <div className='widget widget_text clearfix'>
                  <div className='footer-logo'>
                    <img
                      id='footer-logo-img'
                      className='img-fluid auto_size'
                      height='40'
                      width='162'
                      src='images/Logo.svg'
                      alt='footer-logo' />
                  </div>
                  <p className='text-theme-DarkColor'>
                  Atlas  is a multi award-winning executive search, management recruiter and employer branding organisation. Our customised recruiting process delivers fast & accurate results.
                  </p>
                  <div className='d-flex align-items-center pt-15 pb-25'>
                    <h6 className='fw-normal'>Social Share:</h6>
                    <div className='social-icons'>
                      <ul className='social-icons list-inline'>
                        {/* <li>
                          <a href='#facebook' rel='noopener' aria-label='facebook'><i className='ti ti-facebook'></i></a>
                        </li>
                        <li>
                          <a href='#twitter' rel='noopener' aria-label='twitter'><i className='ti ti-twitter-alt'></i></a>
    </li> */}
                       
                        <li>
                          <a href="https://www.linkedin.com/in/atlas-dias-615494225?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app " rel='noopener' aria-label='linkedin'><i className="ti ti-linkedin" style={{color:'#0077B5'}}></i></a>
                        </li>
                        <li>
                          <a href='https://www.instagram.com/atlasdiasqatar?igsh=NnZrdWsyY3NpMTdl' rel='noopener' aria-label='instagram'><i className='ti ti-instagram' style={{color:'#833AB4'}}></i></a>
                        </li> 
                      </ul>
                    </div>
                  </div>
                  <a className='ttm-btn ttm-btn-size-md ttm-btn-shape-rounded ttm-btn-style-border' href={process.env.PUBLIC_URL + '/contact_01'}>Contact Us</a>
                </div>
              </div>
              <div className='col-xs-12 col-sm-6 col-md-6 col-lg-4 widget-area'>
                <div className='widget widget-recent-post clearfix'>
                  <h3 className='widget-title'>Quick Link</h3>
                  <ul className='widget-post ttm-recent-post-list text-theme-DarkColor'>
                    <li>
                      <div className='post-detail'>
                        <div className='cat-link'>
                          <a href={process.env.PUBLIC_URL + '/'}>Home</a>
                        </div>
                        <a href={process.env.PUBLIC_URL + '/'}></a>
                      </div>
                    </li>
                    <li>
                      <div className='post-detail'>
                        <div className='cat-link'>
                          <a href={process.env.PUBLIC_URL + '/about_us'}>Who We Are</a>
                        </div>
                        <a href={process.env.PUBLIC_URL + '/'}></a>
                      </div>
                    </li>
                    <li>
                      <div className='post-detail'>
                        <div className='cat-link'>
                          <a href={process.env.PUBLIC_URL + '/services'}>Our Expertise</a>
                        </div>
                        <a href={process.env.PUBLIC_URL + '/services'}></a>
                      </div>
                    </li>
                    <li>
                      <div className='post-detail'>
                        <div className='cat-link'>
                          <a href={process.env.PUBLIC_URL + '/services_details'}>Services Details</a>
                        </div>
                        <a href={process.env.PUBLIC_URL + '/services_details'}></a>
                      </div>
                    </li>
                    <li>
                      <div className='post-detail'>
                        <div className='cat-link'>
                          <a href={process.env.PUBLIC_URL + '/carrer_01'}>Careers</a>
                        </div>
                        <a href={process.env.PUBLIC_URL + '/carrer_01'}></a>
                      </div>
                    </li>
                    <li>
                      <div className='post-detail'>
                        <div className='cat-link'>
                          <a href={process.env.PUBLIC_URL + '/Contact_01'}>Contact Us</a>
                        </div>
                        <a href={process.env.PUBLIC_URL + '/Contact_01'}></a>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
              <div className='col-xs-12 col-sm-6 col-md-6 col-lg-4 widget-area'>
                <div className='widget widget-contact clearfix'>
                  <h3 className='widget-title'>Quick Contact!</h3>
                  <ul className='widget_contact_wrapper'>
                  <li>
                      <i className='ttm-textcolor-skincolor flaticon-email'></i>
                      <h3>Email:</h3><a href='mailto:info@example.com'>info@atlasindia.co  jobs@atlasindia.co</a>
                    </li>
                    <li>
                      <i className='ttm-textcolor-skincolor flaticon-phone-call'></i>
                      <h3>Call Us On:</h3><p className='text-theme-DarkColor'>India:+91 7400 493245<br/> Qatar:+974 70254338 </p>
                    </li>
                    <li>
                      <i className='ttm-textcolor-skincolor flaticon-placeholder'></i>
                      <h3>Address:</h3><p className='text-theme-DarkColor'>India: Office No. 1118, 11th floor, Hubtown Solaris, N.S.Phadke Marg, Andheri (East), Mumbai 400056.
                      <br/><br/> Qatar: Office No. 90, Street No. 312, Building No. 104, Ahli Bank Building No. 104, Al Wakra Main Street.</p>
                    </li>
                   
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='bottom-footer-text'>
          <div className='container'>
            <div className='row'>
              <div className='col-lg-8 col-md-6'>
                <span className='copyright'>Copyright © 2024 <a href={'https://triocorporation.in/'}>Triocorporation</a> All rights reserved.</span>
                <ul className='footer-nav-menu'>
                  <li>
                    <a href={process.env.PUBLIC_URL + '/'}>Privacy & Policy</a>
                  </li>
                  <li>
                    <a href={process.env.PUBLIC_URL + '/'}>Terms & Conditions</a>
                  </li>
                </ul>
              </div>
              <div className='col-lg-4 col-md-6 d-lg-flex align-items-center justify-content-between'>
                <form id='subscribe-form' className='subscribe-form' method="post" encType="multipart/form-data" onSubmit={this.handleSubmit}>
                  <div className='mailchimp-inputbox clearfix' id='subscribe-content'>
                    <input
                      type='email'
                      name='email'
                      id='txtemail'
                      placeholder='Enter Your Email Address...'
                      value={this.state.formData.email}
                onChange={this.handleChange}
                      required='' />
                    <button className='submit ttm-btn btn-inline ttm-btn-size-md ttm-btn-color-Darkcolor' type='submit'>
                      <i className='fa fa-paper-plane' aria-hidden='true'></i>
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </footer>
    )
  }
}
