import React, { Component } from 'react'
import Header from '../components/layout/Header'
import PageHeader from '../components/layout/PageHeader'
import { Footer } from '../components/layout/Footer'

import axios from 'axios';

export class Carrer_01 extends Component {

  constructor(props) {
    super(props);
    this.fileInputRef = React.createRef();
    this.state = {
      formData: {
        name: '',
        email: '',
        subject: '',
        phone: '',
        message: '',
        cv: null
      }
    };
  }
  handleChange = (e) => {
    const { name, value } = e.target;
    this.setState(prevState => ({
      formData: {
        ...prevState.formData,
        [name]: value
      }
    }));
  }
  clearFileInput = () => {
    this.fileInputRef.current.value = '';
  };
  handleFileChange = (e) => {
    const file = e.target.files[0];
    this.setState(prevState => ({
      formData: {
        ...prevState.formData,
        cv: file
      }
    }));
  }

  handleSubmit = async (e) => {
    
    e.preventDefault();
    
    const { formData } = this.state;
    try {
      const formDataToSend = new FormData();
      formDataToSend.append('name', formData.name);
      formDataToSend.append('email', formData.email);
      formDataToSend.append('subject', formData.subject);
      formDataToSend.append('phone', formData.phone);
      formDataToSend.append('message', formData.message);
      formDataToSend.append('cv', formData.cv, formData.cv.name); // Append file with its name
    
      console.log('Form Data to Send:', formDataToSend);
     
      //  const response = await axios.post('http://localhost:4000/sendmail/', formDataToSend);
      // const response = await axios.post('https://atlas-back-end.vercel.app/sendmail/', formDataToSend);
      const response = await axios.post('https://node.atlasindia.co/sendmail/', formDataToSend);
    
      console.log('Response:', response.data);
      // Reset form after successful submission
      this.setState({
        formData: {
          name: '',
          email: '',
          subject: '',
          phone: '',
          message: '',
          cv: null
        }
      });
      this.clearFileInput();

      alert('Message sent successfully!');
    } catch (error) {
      console.error('Error sending message:', error);
      alert('An error occurred while sending the message. Please try again later.');
    }
  };


  componentDidMount() {
    const urlParams = new URLSearchParams(window.location.search);
    const focusParam = urlParams.get('focus');
    if (focusParam === 'true') {
      // Focus on the input field when the component mounts
      document.querySelector('#contact_form input[name="name"]').focus();
    }
  }
  render () {
    const { formData } = this.state;
    return (
      <div className='site-main'>
        <Header />
        {/* PageHeader */}
        <PageHeader title='Careers' breadcrumb='Careers' />
        {/* PageHeader end */}
        {/* features-section */}
        <section className='ttm-row features-section clearfix'>
          <div className='container'>
            {/* row */}
            <div className='row'>
              <div className='col-lg-12'>
                {/* section title */}
                <div className='section-title style2'>
                  <div className='title-header'>
                    <h3>popular <span className='text-theme-SkinColor'>jobs</span></h3>
                    <h2 className='title'>Search Job By Industry</h2>
                  </div>
                  <div className='title-desc'>
                    <p>
                      A process that involves everything from <span className='text-theme-SkinColor'>identifying,</span> attracting, shortlisting, interviewing, selecting,
                      hiring, screening & onboarding employees.
                    </p>
                  </div>
                </div>
                {/* section title end */}
              </div>
            </div>
            {/* row end */}
            {/* row */}
            {/* row */}
            <div className='row row-equal-height mb_10'>
              <div className='col-lg-4 col-md-6 col-sm-6'>
                {/* featured-icon-box */}
                <div className='featured-icon-box icon-align-top-content style1'>
                  <div className='ttm-box-view-overlay'>
                    {/* finance-hover */}
                    <div className='ttm-col-bgimage-yes ttm-bg h-100'>
                      <div className='ttm-col-wrapper-bg-layer ttm-bg-layer' style={{ backgroundImage: 'url(images/Service/1.png)' }}></div>
                      <div className='layer-content'></div>
                    </div>
                    {/* finance-hover end */}
                  </div>
                  <div className='featured-icon'>
                    <div className='ttm-icon ttm-icon_element-fill ttm-icon_element-style-round ttm-icon_element-color-grey ttm-icon_element-size-lg'>
                      <img className='img-fluid' src='images/drilling.png' />
                    </div>
                  </div>
                  <div className='featured-content'>
                    <div className='featured-title'>
                      <h3>Drilling & Well Services</h3>
                    </div>
                    <div className='featured-desc'>
                      {/* <p>
                        85 Jobs
                        <br></br>Financial Branch Manager
                      </p> */}
                    </div>
                    <a className='ttm-btn btn-inline ttm-btn-size-md ttm-btn-color-dark' href={process.env.PUBLIC_URL + '/carrer_01?focus=true'}>Apply Jobs!</a>
                  </div>
                </div>
                {/* featured-icon-box end */}
              </div>
              <div className='col-lg-4 col-md-6 col-sm-6'>
                {/* featured-icon-box */}
                <div className='featured-icon-box icon-align-top-content style1'>
                  <div className='ttm-box-view-overlay'>
                    {/* technology-hover */}
                    <div className='ttm-col-bgimage-yes ttm-bg h-100'>
                      <div className='ttm-col-wrapper-bg-layer ttm-bg-layer' style={{ backgroundImage: 'url(images/Service/2.png)' }}></div>
                      <div className='layer-content'></div>
                    </div>
                    {/* technology-hover end */}
                  </div>
                  <div className='featured-icon'>
                    <div className='ttm-icon ttm-icon_element-fill ttm-icon_element-style-round ttm-icon_element-color-grey ttm-icon_element-size-lg'>
                      <img className='img-fluid' src='images/Career/2.png' />
                    </div>
                  </div>
                  <div className='featured-content'>
                    <div className='featured-title'>
                      <h3>Marine</h3>
                    </div>
                    <div className='featured-desc'>
                      {/* <p>
                        110 Jobs
                        <br></br>Software, Hardware, SEO
                      </p> */}
                    </div>
                    <a className='ttm-btn btn-inline ttm-btn-size-md ttm-btn-color-dark' href={process.env.PUBLIC_URL + '/carrer_01?focus=true'}>Apply Jobs!</a>
                  </div>
                </div>
                {/* featured-icon-box end */}
              </div>
              <div className='col-lg-4 col-md-6 col-sm-6'>
                {/* featured-icon-box */}
                {/* <div className='featured-icon-box icon-align-top-content style1 active'> */}
                <div className='featured-icon-box icon-align-top-content style1'>
                  <div className='ttm-box-view-overlay'>
                    {/* agriculture-hover */}
                    <div className='ttm-col-bgimage-yes ttm-bg h-100'>
                      <div className='ttm-col-wrapper-bg-layer ttm-bg-layer' style={{ backgroundImage: 'url(images/Service/3.png)' }}></div>
                      <div className='layer-content'></div>
                    </div>
                    {/* agriculture-hover end */}
                  </div>
                  <div className='featured-icon'>
                    <div className='ttm-icon ttm-icon_element-fill ttm-icon_element-style-round ttm-icon_element-color-grey ttm-icon_element-size-lg'>
                      <img className='img-fluid' src='images/Career/3.png' />
                    </div>
                  </div>
                  <div className='featured-content'>
                    <div className='featured-title'>
                      <h3>Dredging</h3>
                    </div>
                    <div className='featured-desc'>
                      {/* <p>
                        100 Jobs
                        <br></br>Agricultural Food Scientist
                      </p> */}
                    </div>
                    <a className='ttm-btn btn-inline ttm-btn-size-md ttm-btn-color-dark' href={process.env.PUBLIC_URL + '/carrer_01?focus=true'}>Apply Jobs!</a>
                  </div>
                </div>
                {/* featured-icon-box end */}
              </div>
              <div className='col-lg-4 col-md-6 col-sm-6'>
                {/* featured-icon-box */}
                <div className='featured-icon-box icon-align-top-content style1'>
                  <div className='ttm-box-view-overlay'>
                    {/* construction-hover */}
                    <div className='ttm-col-bgimage-yes ttm-bg h-100'>
                      <div className='ttm-col-wrapper-bg-layer ttm-bg-layer' style={{ backgroundImage: 'url(images/Service/4.png)' }}></div>
                      <div className='layer-content'></div>
                    </div>
                    {/* construction-hover end */}
                  </div>
                  <div className='featured-icon'>
                    <div className='ttm-icon ttm-icon_element-fill ttm-icon_element-style-round ttm-icon_element-color-grey ttm-icon_element-size-lg'>
                      <img className='img-fluid' src='images/cat-icon4.png' />
                    </div>
                  </div>
                  <div className='featured-content'>
                    <div className='featured-title'>
                      <h3>Construction</h3>
                    </div>
                    <div className='featured-desc'>
                      {/* <p>
                        120 Jobs
                        <br></br>The Marketing Coordinator
                      </p> */}
                    </div>
                    <a className='ttm-btn btn-inline ttm-btn-size-md ttm-btn-color-dark' href={process.env.PUBLIC_URL + '/carrer_01?focus=true'}>Apply Jobs!</a>
                  </div>
                </div>
                {/* featured-icon-box end */}
              </div>
              <div className='col-lg-4 col-md-6 col-sm-6'>
                {/* featured-icon-box */}
                <div className='featured-icon-box icon-align-top-content style1'>
                  <div className='ttm-box-view-overlay'>
                    {/* manufacturing-hover */}
                    <div className='ttm-col-bgimage-yes ttm-bg h-100'>
                      <div className='ttm-col-wrapper-bg-layer ttm-bg-layer' style={{ backgroundImage: 'url(images/Service/5.png)' }}></div>
                      <div className='layer-content'></div>
                    </div>
                    {/* manufacturing-hover end */}
                  </div>
                  <div className='featured-icon'>
                    <div className='ttm-icon ttm-icon_element-fill ttm-icon_element-style-round ttm-icon_element-color-grey ttm-icon_element-size-lg'>
                      {/* <img className='img-fluid' src='images/cat-icon5.png' /> */}
                      <img className='img-fluid' src='images/Career/4.png' />
                    </div>
                  </div>
                  <div className='featured-content'>
                    <div className='featured-title'>
                      <h3>White & Blue Collar</h3>
                    </div>
                    <div className='featured-desc'>
                      {/* <p>
                        100 Jobs
                        <br></br>Quality Control Inspector
                      </p> */}
                    </div>
                    <a className='ttm-btn btn-inline ttm-btn-size-md ttm-btn-color-dark' href={process.env.PUBLIC_URL + '/carrer_01?focus=true'}>Apply Jobs!</a>
                  </div>
                </div>
                {/* featured-icon-box end */}
              </div>
              <div className='col-lg-4 col-md-6 col-sm-6'>
                {/* featured-icon-box */}
                <div className='featured-icon-box icon-align-top-content style1'>
                  <div className='ttm-box-view-overlay'>
                    {/* manufacturing-hover */}
                    <div className='ttm-col-bgimage-yes ttm-bg h-100'>
                      <div className='ttm-col-wrapper-bg-layer ttm-bg-layer' style={{ backgroundImage: 'url(images/AtlasLogo.png)' }}></div>
                      <div className='layer-content'></div>
                    </div>
                    {/* manufacturing-hover end */}
                  </div>
                  <div className='featured-icon'>
                    <div className='ttm-icon ttm-icon_element-fill ttm-icon_element-style-round ttm-icon_element-color-grey ttm-icon_element-size-lg'>
                      {/* <img className='img-fluid' src='images/cat-icon5.png' /> */}
                      <img className='img-fluid' src='images/AtlasLogo.png' />
                    </div>
                  </div>
                  <div className='featured-content'>
                    <div className='featured-title'>
                      <h3>Career</h3>
                    </div>
                    <div className='featured-desc'>
                      {/* <p>
                        100 Jobs
                        <br></br>Quality Control Inspector
                      </p> */}
                    </div>
                    <a className='ttm-btn btn-inline ttm-btn-size-md ttm-btn-color-dark' href={process.env.PUBLIC_URL + '/carrer_01?focus=true'}>Apply Jobs!</a>
                  </div>
                </div>
                {/* featured-icon-box end */}
              </div>
            </div>
            {/* row end */}
          </div>
        </section>
        {/* features-section end */}
        {/* process-section */}
        {/* <section className='ttm-row process-section bg-theme-GreyColor clearfix'>
          <div className='container'>
            <div className='row'>
              <div className='col-lg-12'>
                <div className='section-title title-style-center_text'>
                  <div className='title-header'>
                    <h3>How <span className='text-theme-SkinColor'>we do!</span></h3>
                    <h2 className='title'>The Hiring Process</h2>
                  </div>
                </div>
              </div>
            </div>
          
            <div className='row'>
              <div className='col-lg-12'>
                <div className='featuredbox-number bg-theme-WhiteColor box-shadow p-30 mt-15'>
                  <div className='row'>
                    <div className='col-lg-4 col-md-4 col-sm-12'>
                  
                      <div className='featured-icon-box icon-align-top-content style6'>
                        <div className='featured-icon'>
                          <div className='ttm-icon ttm-icon_element-fill ttm-icon_element-size-lg ttm-icon_element-color-grey ttm-icon_element-style-round'>
                            <i className='flaticon-headhunting'></i>
                            <span className='fea_num'><i className='ttm-num ti-info'></i></span>
                          </div>
                        </div>
                        <div className='featured-content'>
                          <div className='featured-title'>
                            <h3>Identify Need</h3>
                          </div>
                          <div className='featured-desc'>
                            <p>
                              This need could vary from filling a vacated position, better managing a team workload, or expanding.
                            </p>
                          </div>
                        </div>
                      </div>
                    
                    </div>
                    <div className='col-lg-4 col-md-4 col-sm-12'>
                     
                      <div className='featured-icon-box icon-align-top-content style6'>
                        <div className='featured-icon'>
                          <div className='ttm-icon ttm-icon_element-fill ttm-icon_element-size-lg ttm-icon_element-color-grey ttm-icon_element-style-round'>
                            <i className='flaticon flaticon-human-resources'></i>
                            <span className='fea_num'><i className='ttm-num ti-info'></i></span>
                          </div>
                        </div>
                        <div className='featured-content'>
                          <div className='featured-title'>
                            <h3>Review Process</h3>
                          </div>
                          <div className='featured-desc'>
                            <p>
                              HR department who review the applications and eliminate any candidate who does not meet the minimum requirements
                            </p>
                          </div>
                        </div>
                      </div>
                      
                    </div>
                    <div className='col-lg-4 col-md-4 col-sm-12'>
                     
                      <div className='featured-icon-box icon-align-top-content style6'>
                        <div className='featured-icon'>
                          <div className='ttm-icon ttm-icon_element-fill ttm-icon_element-size-lg ttm-icon_element-color-grey ttm-icon_element-style-round'>
                            <i className='flaticon flaticon-interview'></i>
                            <span className='fea_num'><i className='ttm-num ti-info'></i></span>
                          </div>
                        </div>
                        <div className='featured-content'>
                          <div className='featured-title'>
                            <h3>Interviews</h3>
                          </div>
                          <div className='featured-desc'>
                            <p>
                              The organization and hiring committee, one or several interviews are scheduled for those remaining candidates
                            </p>
                          </div>
                        </div>
                      </div>
                      
                    </div>
                  </div>
                </div>
              </div>
            </div>
        
          </div>
        </section> */}
        {/* process-section end */}
        {/* portfolio-section */}
        {/* portfolio-section end */}
        {/* job_pos-section */}
        {/* job_pos-section end */}
        {/* contact-section */}
        <section className='ttm-row contact-section bg-theme-GreyColor clearfix'>
          <div className='container'>
            <div className='row'>
              <div className='col-lg-12'>
                {/* section-title */}
                <div className='section-title title-style-center_text'>
                  <div className='title-header'>
                    <h3>Contact us</h3>
                    <h2 className='title'>Get In Touch With Us</h2>
                  </div>
                </div>
                {/* section-title end */}
              </div>
            </div>
            {/* row */}
            <div className='row'>
              <div className='col-lg-6'>
                <div className='pr-30 pr-md-0 mt-15'>
                  <div className='ttm_single_image-wrapper mb-20'>
                    <img className='img-fluid w-100' src='images/About/GetInTouch.png' alt='single_06' />
                  </div>
                  <p className='mb-30'>
                    Reach out to Atlas for all your maritime staffing needs. Our dedicated team is ready to assist you in finding the perfect talent for your operations.
                    <br/>
                    <br/>Connect with us today to discuss how we can support your recruitment goals and help propel your maritime endeavors forward.
                  </p>
                
                  <div className='d-flex align-items-center'>
                    <div className='ttm-icon ttm-icon_element-fill ttm-icon_element-color-skincolor ttm-icon_element-size-sm
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                  ttm-icon_element-style-round mb-0'>
                      <i className='fa fa-globe'></i>
                    </div>
                    <div className='pl-15'>
                      <h6 className='mb-5'>Send Email</h6>
                      <p className='featured-desc mb-0'>
                        info@atlasindia.co <br/>
                        jobs@atlasindia.co
                      </p>
                    </div>
                  </div>
                  <div className='ttm-horizontal_sep width-100 mt-20 mb-20'></div>
                  <div className='d-flex align-items-center'>
                    <div className='ttm-icon ttm-icon_element-fill ttm-icon_element-color-skincolor ttm-icon_element-size-sm
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                  ttm-icon_element-style-round mb-0'>
                      <i className='fa fa-phone'></i>
                    </div>
                    <div className='pl-15'>
                      <h6 className='mb-5'>Call Anytime</h6>
                      <p className='featured-desc mb-0'>
                        India:+91 7400 493245 <br/>
                         Qatar:+974 70254338 
                      </p>
                    </div>
                  </div>
                  <div className='ttm-horizontal_sep width-100 mt-20 mb-20'></div>
                  <div className='d-flex align-items-center'>
                    <div className='ttm-icon ttm-icon_element-fill ttm-icon_element-color-skincolor ttm-icon_element-size-sm
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                  ttm-icon_element-style-round mb-0'>
                      <i className='ti ti-home'></i>
                    </div>
                    <div className='pl-15'>
                      <h6 className='mb-5'>Visit Office</h6>
                      <p className='featured-desc mb-0'>
                      India - Mumbai 
                        <br/> Qatar - Doha 
                        <br/> UAE - Dubai 
                      </p>
                    </div>
                  </div>
                  {/*  featured-icon-box end */}
                </div>
              </div>
              <div className='col-lg-6'>
                <div className='ttm-col-bgcolor-yes ttm-bg bg-theme-WhiteColor z-index-2 p-40 p-lg-30 mt-15 mt-lg-30'>
                  <div className='ttm-col-wrapper-bg-layer ttm-bg-layer'></div>
                  <div className='layer-content'>
                    <form id='contact_form' className='contact_form wrap-form' method="post" encType="multipart/form-data" onSubmit={this.handleSubmit}>
                      <div className='row ttm-boxes-spacing-15px'>
                        <div className='col-md-12 ttm-box-col-wrapper'>
                          <label>
                            <input
                              name='name'
                              type='text'
                              placeholder='Your Name'
                              required='required'
                              value={this.state.formData.name}
                              onChange={this.handleChange}
                              />
                          </label>
                        </div>
                        <div className='col-md-6 ttm-box-col-wrapper'>
                          <label>
                            <input
                              name='email'
                              type='text'
                              placeholder='Your Email'
                              required='required'
                              value={this.state.formData.email}
                onChange={this.handleChange}
                              />
                          </label>
                        </div>
                        <div className='col-md-6 ttm-box-col-wrapper'>
                          <label>
                            <input
                              name='subject'
                              type='text'
                              placeholder='Subject'
                              required='required'
                              value={this.state.formData.subject}
                onChange={this.handleChange}
                              />
                          </label>
                        </div>
                        <div className='col-md-12 ttm-box-col-wrapper'>
                          <label>
                            <input
                              name='phone'
                              type='text'
                              placeholder='Phone Number'
                              required='required'
                              value={this.state.formData.phone}
                onChange={this.handleChange}
                              />
                          </label>
                        </div>
                        <div className='col-md-12 ttm-box-col-wrapper'>
                          <label>
                            <textarea
                              name='message'
                              rows='9'
                              placeholder='Message'
                              required='required'
                              value={this.state.formData.message}
                onChange={this.handleChange}
                              ></textarea>
                          </label>
                        </div>
                        <div className='col-md-12 ttm-box-col-wrapper'>
                        <label style={{display: 'block'}}>
  Upload CV: <span style={{ color: 'blue' }}>(only .doc or .docx)</span>
                            <input
                              type='file'
                              name='cv'
                              accept='.doc,.docx'
                              onChange={this.handleFileChange}
                              ref={this.fileInputRef}
                              required />
                          </label>
                        </div>
                      </div>
                      <button className='submit ttm-btn ttm-btn-size-md ttm-btn-shape-rounded ttm-btn-style-fill ttm-btn-color-skincolor w-100' type='submit'>
                        send a message!
                      </button>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* contact-section end */}
        {/* action-section */}
        <section className='ttm-row action-section bg-theme-SkinColor text-theme-WhiteColor clearfix'>
          <div className='container'>
            <div className='row'>
              <div className='col-lg-12'>
                <div className='d-md-flex align-items-center justify-content-between'>
                  {/* featured-icon-box */}
                  <div className='featured-icon-box icon-align-before-content style2'>
                    <div className='featured-icon'>
                      <div className='ttm-icon ttm-icon_element-onlytxt ttm-icon_element-color-white ttm-icon_element-size-xl'>
                        <i className='flaticon flaticon-recruitment-5'></i>
                      </div>
                    </div>
                    <div className='featured-content'>
                      <div className='featured-title'>
                        <h3>Incredible Recruitment & Staffing Specialist</h3>
                      </div>
                      <div className='featured-desc'>
                        {/* <p>
                          We have over 30 years experience oft Business porro qusquam dol ipsum quia dolor sit amet.
                        </p> */}
                      </div>
                    </div>
                  </div>
                  {/* featured-icon-box end */}
                  <a className='ttm-btn ttm-btn-size-md ttm-btn-shape-rounded ttm-btn-style-border ttm-btn-color-white' href={process.env.PUBLIC_URL + '/carrer_01?focus=true'}>Hiring Now!</a>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* action-section end */}
        <Footer/>
      </div>
    )
  }
}

export default Carrer_01
