
import React from "react";
const ServicesDetail1 = () =>
{
    return(
        <>
          <div className="col-lg-8 content-area order-lg-2">
                                {/* ttm-service-single-content-area */}
                                <div className="ttm-service-single-content-area">
                                    <div className="ttm_single_image-wrapper">
                                        <img className="img-fluid" src="images/ServicesDetail/2.png" alt="services-img" />
                                    </div>
                                    <div className="ttm-service-description mt-40">
                                        <h3 className="mb-20">Description For Our Service</h3>
                                        <p>Providing skilled manpower for the marine industry is essential for ensuring the safe and efficient operation of vessels, ports, offshore installations, and other maritime activities. Here's how Atlas ensures a reliable and competent workforce for marine services</p>
                                        <div className="row">
                                            <div className="col-lg-6 col-md-6 col-sm-6">
                                                {/* featured-icon-box */} 
                                                <div className="featured-icon-box icon-align-before-content icon-ver_align-top mr-50 mr-md-0 mt-20">
                                                    <div className="featured-icon">
                                                        <div className="ttm-icon ttm-icon_element-onlytxt ttm-icon_element-color-skincolor ttm-icon_element-size-lg">
                                                            <i className="flaticon-research"></i>
                                                        </div>
                                                    </div>
                                                    <div className="featured-content">
                                                        <div className="featured-title">
                                                            <h3>Recruitment Expertise</h3>
                                                        </div>
                                                        <div className="featured-desc">
                                                            <p>Atlas specializes in sourcing and recruiting highly skilled personnel with expertise in various aspects of the marine industry. Our recruitment team identifies candidates with relevant qualifications, certifications, and experience to meet the specific needs of each marine project.</p>
                                                        </div>
                                                    </div>
                                                </div>{/* featured-icon-box end */}
                                            </div>
                                            <div className="col-lg-6 col-md-6 col-sm-6">
                                                {/* featured-icon-box */}
                                                <div className="featured-icon-box icon-align-before-content icon-ver_align-top mr-50 mr-md-0 mt-20">
                                                    <div className="featured-icon">
                                                        <div className="ttm-icon ttm-icon_element-onlytxt ttm-icon_element-color-skincolor ttm-icon_element-size-lg">
                                                            <i className="flaticon-healthcare"></i>
                                                        </div>
                                                    </div>
                                                    <div className="featured-content">
                                                        <div className="featured-title">
                                                            <h3>Qualified Seafarers</h3>
                                                        </div>
                                                        <div className="featured-desc">
                                                            <p>We prioritize hiring qualified seafarers for roles such as deck officers, engineers, ratings, and other essential positions onboard vessels. This includes ensuring that seafarers possess the necessary STCW certifications, maritime licenses, and proficiency in safety procedures.</p>
                                                        </div>
                                                    </div>
                                                </div>{/* featured-icon-box end */}
                                            </div>
                                            <div className="col-lg-6 col-md-6 col-sm-6">
                                                {/* featured-icon-box */}
                                                <div className="featured-icon-box icon-align-before-content icon-ver_align-top mr-50 mr-md-0 mt-20">
                                                    <div className="featured-icon">
                                                        <div className="ttm-icon ttm-icon_element-onlytxt ttm-icon_element-color-skincolor ttm-icon_element-size-lg">
                                                            <i className="flaticon-conveyor"></i>
                                                        </div>
                                                    </div>
                                                    <div className="featured-content">
                                                        <div className="featured-title">
                                                            <h3>Technical Expertise</h3>
                                                        </div>
                                                        <div className="featured-desc">
                                                            <p> Atlas recruits marine professionals with technical expertise in vessel operations, navigation, engineering, maintenance, and safety management. This includes officers and crew with experience in operating different types of vessels, including cargo ships, tankers, offshore support vessels, and passenger vessels.</p>
                                                        </div>
                                                    </div>
                                                </div>{/* featured-icon-box end */}
                                            </div>
                                            <div className="col-lg-6 col-md-6 col-sm-6">
                                                {/* featured-icon-box */}
                                                <div className="featured-icon-box icon-align-before-content icon-ver_align-top mr-50 mr-md-0 mt-20">
                                                    <div className="featured-icon">
                                                        <div className="ttm-icon ttm-icon_element-onlytxt ttm-icon_element-color-skincolor ttm-icon_element-size-lg">
                                                            <i className="flaticon-human-resources-3"></i>
                                                        </div>
                                                    </div>
                                                    <div className="featured-content">
                                                        <div className="featured-title">
                                                            <h3>Safety Training</h3>
                                                        </div>
                                                        <div className="featured-desc">
                                                            <p> Safety is paramount in the marine industry. Atlas ensures that all seafarers undergo rigorous safety training to adhere to international maritime regulations and industry best practices. This includes training on emergency procedures, firefighting, survival at sea, and personal safety measures.</p>
                                                        </div>
                                                    </div>
                                                </div>{/* featured-icon-box end */}
                                            </div>
                                            <div className="col-lg-6 col-md-6 col-sm-6">
                                                {/* featured-icon-box */}
                                                <div className="featured-icon-box icon-align-before-content icon-ver_align-top mr-50 mr-md-0 mt-20">
                                                    <div className="featured-icon">
                                                        <div className="ttm-icon ttm-icon_element-onlytxt ttm-icon_element-color-skincolor ttm-icon_element-size-lg">
                                                            <i className="flaticon-human-resources-3"></i>
                                                        </div>
                                                    </div>
                                                    <div className="featured-content">
                                                        <div className="featured-title">
                                                            <h3>Continuous Development</h3>
                                                        </div>
                                                        <div className="featured-desc">
                                                            <p>We invest in the continuous training and development of our marine manpower to enhance their skills and keep them updated with the latest technologies and regulations. This includes technical training on vessel systems, navigation equipment, pollution prevention measures, and environmental compliance.</p>
                                                        </div>
                                                    </div>
                                                </div>{/* featured-icon-box end */}
                                            </div>
                                            <div className="col-lg-6 col-md-6 col-sm-6">
                                                {/* featured-icon-box */}
                                                <div className="featured-icon-box icon-align-before-content icon-ver_align-top mr-50 mr-md-0 mt-20">
                                                    <div className="featured-icon">
                                                        <div className="ttm-icon ttm-icon_element-onlytxt ttm-icon_element-color-skincolor ttm-icon_element-size-lg">
                                                            <i className="flaticon-human-resources-3"></i>
                                                        </div>
                                                    </div>
                                                    <div className="featured-content">
                                                        <div className="featured-title">
                                                            <h3>Manpower Planning  </h3>
                                                        </div>
                                                        <div className="featured-desc">
                                                            <p>Atlas conducts thorough manpower planning to anticipate staffing needs for maritime projects. This includes forecasting crew requirements, assessing skill gaps, and implementing recruitment strategies to ensure the availability of qualified personnel for vessels, ports, and offshore installations.</p>
                                                        </div>
                                                    </div>
                                                </div>{/* featured-icon-box end */}
                                            </div>
                                            <div className="col-lg-6 col-md-6 col-sm-6">
                                                {/* featured-icon-box */}
                                                <div className="featured-icon-box icon-align-before-content icon-ver_align-top mr-50 mr-md-0 mt-20">
                                                    <div className="featured-icon">
                                                        <div className="ttm-icon ttm-icon_element-onlytxt ttm-icon_element-color-skincolor ttm-icon_element-size-lg">
                                                            <i className="flaticon-human-resources-3"></i>
                                                        </div>
                                                    </div>
                                                    <div className="featured-content">
                                                        <div className="featured-title">
                                                            <h3>Performance Monitoring</h3>
                                                        </div>
                                                        <div className="featured-desc">
                                                            <p>We monitor the performance of our marine manpower closely to ensure compliance with safety standards, operational efficiency, and customer satisfaction. This includes regular performance evaluations, feedback mechanisms, and corrective actions to address any issues or challenges that may arise.</p>
                                                        </div>
                                                    </div>
                                                </div>{/* featured-icon-box end */}
                                            </div>
                                        </div>
                                        <p>By prioritizing recruitment, training, safety, and performance monitoring, Atlas ensures a skilled and reliable workforce for dredging services, enabling successful and efficient dredging projects in the maritime and construction sectors.</p>
                                        <div className="ttm-horizontal_sep width-100 mt-25 mb-35"></div>
                                        <div className="row">
                                            <div className="col-md-6">
                                                <h4>Marine</h4>
                                                <p>Atlas offers excellent career opportunities in the offshore construction sector and on offshore support & supply vessels within the marine industry. Choosing Atlas for a career in offshore marine promises challenging roles at all levels, accompanied by abundant opportunities for career development and growth.</p>
                                                <h4>How to get there?</h4>
                                                {/* <ul className="ttm-list ttm-list-style-square ttm-list-icon-color-skincolor mb-30">
                                                    <li>
                                                        <div className="ttm-list-li-content">Lorem ipsum dolor sit amet, consectetur adipi</div>
                                                    </li>
                                                    <li>
                                                        <div className="ttm-list-li-content">There are many variations of passages of Lorem</div>
                                                    </li>
                                                    <li>
                                                        <div className="ttm-list-li-content">The standard chunk of Lorem Ipsum used since</div>
                                                    </li>
                                                    <li>
                                                        <div className="ttm-list-li-content">Contrary to popular belief, Lorem Ipsum</div>
                                                    </li>
                                                </ul> */}
                                            </div>
                                            <div className="col-md-6">
                                                <div className="ttm_single_image-wrapper mb-30">
                                                    <img className="img-fluid" src="images/ServicesDetail/2.svg" alt="services-img" />
                                                </div>
                                            </div>
                                            <div className="col-lg-12">
                                                <p >Within the Offshore Marine industry, there exist numerous avenues, such as operating self-propelled or stationary equipment, or pursuing land-based positions. Roles involving self-propelled equipment typically necessitate both a nautical certificate (STCW 95) and a relevant secondary vocational degree or higher. For positions involving stationary equipment, proficiency in working on water and machinery, coupled with an offshore marine background, is highly recommended. Experience in the offshore marine industry is a prerequisite for land-based roles.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* ttm-service-single-content-are end */}
                            </div>
        </>
    )

}

export default ServicesDetail1;