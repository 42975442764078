import React, { useState } from 'react';
import FAQ from './FAQ';
import Header from '../components/layout/Header';
import PageHeader from './layout/PageHeader';

import { Footer } from '../components/layout/Footer';


function GetFaq() {
  const [faqs, setfaqs] = useState([
    {
      question: 'In what locations is Atlas active?',
      answer: "Atlas is a niche specialist, operating with dedicated teams in various niche areas. We’re headquartered in India and have our branch offices in Qatar & UAE. We also have tie ups with our counterparts in the Netherlands & Türkiye.",
      open: true
    },
    {
      question: 'Why should we choose Atlas over the other service providers?',
      answer: 'We prioritise quality, reliability, and efficiency in supplying our services Our extensive network and rigorous screening process ensure we deliver skilled and qualified professionals to meet your specific requirements. Additionally, our commitment to exceptional cliental servicing ensures our clients receive the best support throughout their tenure with Atlas.',
      open: false
    },
    {
      question: 'Does Atlas carry out entire crewing?',
      answer: 'Yes, we can take care of all your needs from recruiting professionals, please feel free to reach out to us for more detailed information.',
      open: false
    }
    ,
    {
      question: 'What is the services Atlas provide?',
      answer: 'We provide Manpower with complete logistics and payroll services.',
      open: false
    }
    ,
    {
      question: 'How long does Atlas take to cater to its client’s requirements?',
      answer: 'We prefer to deliver our best in the most efficient manner, we can deliver within 1-3 working days. ',
      open: false
    }
    ,
    {
      question: 'Does Atlas have an RPS license?',
      answer: 'Yes, our India office is approved by DG shipping, India since 2018. Our license number is RPSL-MUM-1001, issued on 8th January 2024 and valid until 8th January 2029.',
      open: false
    }
    
  ]);
  const [faqCandidate, setFaqCandidate] = useState([
    {
      question: 'Why do I have to Upload my CV? ',
      answer: "Your CV is a valuable document for our team as it contains information about your skills, certifications and professional experience. Your CV will be reviewed by our team and you can be contacted when we have a suitable vacancy for you.",
      open: true
    },
    {
      question: 'What is the best format for my CV?',
      answer: 'We only accept CVs created in MS Word, please keep it simple yet up to date with your professional experience and certifications.',
      open: false
    },
    {
      question: 'I have applied for a job; how can I know my status?',
      answer: 'Our team will keep in touch with you throughout the process, allow us 2-3 working days to revert to you with Client feedback of selection.',
      open: false
    }
   
    
  ]);

  // In GetFaq component
const toggleClientFAQ = index => {
  setfaqs(prevFaqs =>
    prevFaqs.map((faq, i) => ({
      ...faq,
      open: i === index ? !faq.open : false
    }))
  );
};

const toggleCandidateFAQ = index => {
  setFaqCandidate(prevCandidateFaqs =>
    prevCandidateFaqs.map((faq, i) => ({
      ...faq,
      open: i === index ? !faq.open : false
    }))
  );
};

console.log("Updated faqs:", faqs);
console.log("Updated faqCandidate:", faqCandidate);

  
  return (
    <div className="App">
        <Header/>
        <PageHeader title='FAQ' breadcrumb='FAQ' />
        {/* <Banner /> */}
       
 <div className="row justify-content-center mt-5">
        <div className="col-lg-6 mb-3 mb-lg-0 d-flex justify-content-center mt-5">
          <button className="submit ttm-btn ttm-btn-size-md ttm-btn-shape-rounded ttm-btn-style-fill ttm-btn-color-skincolor" style={{ width: '50%' }} onClick={() => toggleClientFAQ(0)}>
            FAQ by Client
          </button>
        </div>
        <div className="col-lg-6 d-flex justify-content-center mt-5">
          {/* <button className="submit ttm-btn ttm-btn-size-md ttm-btn-shape-rounded ttm-btn-style-fill ttm-btn-color-skincolor" style={{ width: '50%' }} onClick={() => toggleCandidateFAQ(0)}>
            FAQ by Candidate
          </button> */}
          <button className="submit ttm-btn ttm-btn-size-md ttm-btn-shape-rounded ttm-btn-style-fill ttm-btn-color-skincolor" style={{ width: '50%' }} onClick={() => toggleCandidateFAQ(0)}>
  FAQ by Candidate
</button>

        </div>
      </div>


      <style>
        {`
          .faqs {
            width: 100%;
            max-width: 768px;
            margin: 0 auto;
            padding: 15px;
          }

          .faqs .faq {
            margin: 15px;
            padding: 15px;
            background-color: #FFF;
            border-radius: 8px;
            box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
          }

          .faqs .faq .faq-question {
            position: relative;
            font-size: 20px;
            padding-right: 80px;
            transition: all 0.4s ease;
          }

          .faqs .faq .faq-question::after {
            content: '';
            position: absolute;
            top: 50%;
            right: 0px;
            transform: translateY(-50%);
            width: 30px;
            height: 30px;
            background-image: url('images/arrow-down-mint.svg');
            background-position: center;
            background-size: contain;
            background-repeat: no-repeat;
            transition: all 0.4s ease-out;
          }

          .faqs .faq .faq-answer {
            opacity: 0;
            max-height: 0;
            overflow-y: hidden;
            transition: all 0.4s ease-out;
          }

          .faqs .faq.open .faq-question {
            margin-bottom: 15px;
          }

          .faqs .faq.open .faq-question::after {
            transform: translateY(-50%) rotate(180deg);
          }

          .faqs .faq.open .faq-answer {
            max-height: 1000px;
            opacity: 1;
          }
        `}
      </style>
      
      {/* <div className="faqs">
        {faqs.map((faq, i) => (
          <FAQ  key={i} faq={faq} index={i} toggleFAQ={toggleFAQ} />
        ))}
      </div> */}
      <div className='row'>
        <div className='col-lg-6'>
      <div className="faqs">
        {faqs.map((faq, i) => (
          <FAQ key={i} faq={faq} index={i} toggleFAQ={toggleClientFAQ} />
        ))}
      </div>
      </div>
      <div className='col-lg-6'>
     <div className="faqs">
        {faqCandidate.map((faq, i) => (
          <FAQ key={i} faq={faq} index={i} toggleFAQ={toggleCandidateFAQ} />
        ))}
      </div>
      </div>
      </div>
      {/* <div className="faqs">
        {faqCandidate.map((faq, i) => (
          <FAQ key={i} faq={faq} index={i} toggleFAQ={toggleCandidateFAQ} />
        ))}
      </div> */}
      <Footer />
    </div>
  );
}

export default GetFaq;
