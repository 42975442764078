import React, { Component } from 'react'
import Slider from 'react-slick'
import ProgressBar from 'react-animated-progress-bar'
import Header from '../components/layout/Header'
import PageHeader from '../components/layout/PageHeader'
import { Footer } from '../components/layout/Footer'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs'
import CountUp from 'react-countup'
import Video from '../components/layout/Video'
import ClientSection from '../components/ClientSection'
import bgCompany from '../assets/img/bgCompany.svg'
import { useEffect } from 'react'
import GetInTouch from '../components/GetInTouch'
import OurStory from '../components/OurStory'


export class About_us extends Component {
  constructor (props) {
    super(props)

    this.state = {
      photoIndex: 0,
      isOpen: false
    }
  }

  render () {
    const sliderElements1 = [
      {
        id: 1, description: <div className='col-lg-12'>
                              {/* testimonials */}
                              <div className='testimonials ttm-testimonial-box-view-style2' role='group'>
                                <div className='testimonial-top'>
                                  <div className='testimonial-avatar'>
                                    <div className='testimonial-img'>
                                      <img className='img-fluid' src='images/founder/Kevin.png' alt='testimonial-img' />
                                    </div>
                                  </div>
                                  <div className='testimonial-caption'>
                                    <label>
                                      Our Founder
                                    </label>
                                    <h3>Kevin Dias</h3>
                                  </div>
                                </div>
                                <div className='testimonial-content'>
                                  <blockquote className='testimonial-text'>
                                    Kevin Dias is a seasoned professional with a passion for the maritime industry that runs deep in his veins. Hailing from a family with a rich Offshore maritime heritage,
                                    Kevin's journey in the field began at a young age, where he developed a profound appreciation for the sea and its endless possibilities. With a keen eye
                                    for talent and a natural knack for leadership, Kevin embarked on a mission to revolutionise Offshore maritime recruitment.
                                    <br/> Kevin founded Atlas with the goal of becoming a leader in the sector by connecting skilled offshore crew with exciting opportunities. His unwavering commitment
                                    to integrity, coupled with his innate ability to understand the intricate dynamics of maritime staffing, has propelled Atlas India to new heights under his
                                    leadership.
                                    <br/> As the driving force behind Atlas, Kevin remains dedicated to empowering Offshore maritime ventures with top-tier talent and fostering long-lasting partnerships
                                    built on trust and reliability. With his unparalleled expertise and unwavering determination, Kevin continues to shape the future of maritime recruitment,
                                    one success story at a time.
                                   
                                   
                                  </blockquote>
                                </div>
                              </div>
                              {/* testimonials end */}
                            </div>
      },
      {
        id: 2, description: <div className='col-lg-12'>
                              {/* testimonials */}
                              <div className='testimonials ttm-testimonial-box-view-style2' role='group'>
                                <div className='testimonial-top'>
                                  <div className='testimonial-avatar'>
                                    <div className='testimonial-img'>
                                      <img className='img-fluid' src='images/founder/Renvin.png' alt='testimonial-img' />
                                    </div>
                                  </div>
                                  <div className='testimonial-caption'>
                                    <label>
                                      Our Director
                                    </label>
                                    <h3>Renvin Dias</h3>
                                  </div>
                                </div>
                                <div className='testimonial-content'>
                                  <blockquote className='testimonial-text'>
                                  In 2012, Renvin Dias embarked on his offshore journey, commencing his career in Spain and then moving on to The Netherlands, and the middle-east. Since then, he has traversed the globe, accumulating valuable experience and expertise in the dynamic realm of offshore operations. Alongside his offshore endeavours, Renvin plays a pivotal role as one of the directors of Atlas.
                                    <br/>His journey began as a testament to his passion for adventure and a desire to carve a niche in the offshore industry. Through dedication and perseverance, Renvin quickly rose through the ranks, showcasing his exceptional aptitude for the intricacies of offshore work. His tenure offshore provided him with a solid foundation, equipping him with the practical knowledge and hands-on experience necessary to thrive in this competitive field.
                                    <br/> Today, Renvin Dias stands as a seasoned professional, renowned for his unwavering commitment to excellence and his ability to tackle complex challenges with poise and proficiency. His transition to Qatar in 2018 signifies a new chapter in his career, where he continues to make waves in the offshore industry, contributing to the success of projects and inspiring those around him with his unparalleled dedication and determination as one of the directors of Atlas.
                                   
                                  </blockquote>
                                </div>
                              </div>
                              {/* testimonials end */}
                            </div>
      },

      {
        id: 2, description: <div className='col-lg-12'>
                              {/* testimonials */}
                              <div className='testimonials ttm-testimonial-box-view-style2' role='group'>
                                <div className='testimonial-top'>
                                  <div className='testimonial-avatar'>
                                    <div className='testimonial-img'>
                                      <img className='img-fluid' src='images/founder/Revinka.png' alt='testimonial-img' />
                                    </div>
                                  </div>
                                  <div className='testimonial-caption'>
                                    <label>
                                      Our Director
                                    </label>
                                    <h3>Revinka Dias</h3>
                                  </div>
                                </div>
                                <div className='testimonial-content'>
                                  <blockquote className='testimonial-text'>
                                    Revinka joined Atlas in 2018, as a director and have been heading the entire Operations dynamic force within the Offshore maritime recruitment landscape whose journey
                                    is defined by resilience, passion, and a relentless pursuit of excellence. Growing up amidst the maritime traditions of her family, Revinka developed a deep-seated
                                    admiration for the industry's ethos and its invaluable contribution to global trade and commerce.
                                    <br/> Armed with a strong educational background in human resource management and a fervent desire to make a difference, Revinka embarked on a mission to redefine
                                    Offshore First maritime recruitment practices. With a keen focus on nurturing talent and fostering meaningful connections, driven by a shared vision of excellence
                                    and integrity.
                                    <br/> Revinka's unique blend of empathy, strategic foresight, and unwavering commitment to client satisfaction has been instrumental in establishing Atlas as
                                    a trusted name in offshore maritime recruitment. Her innate ability to understand the needs of both clients and candidates alike enables her to deliver personalised
                                    solutions that drive success and growth for all stakeholders involved.
                                    <br/> As a trailblazer in the industry, Revinka continues to inspire change and innovation, shaping the future of maritime recruitment with her unparalleled dedication
                                    and vision. With Atlas, she remains steadfast in her commitment to empowering individuals and organisations to reach new horizons of success in the Offshore
                                    maritime realm.
                                    <br/>
                                  </blockquote>
                                </div>
                              </div>
                              {/* testimonials end */}
                            </div>
      }

    ]

    var slick_slider = {
      dots: false,
      arrow: false,
      autoplay: true,
      infinite: true,
      speed: 1000,
      slidesToShow: 4,
      slidesToScroll: 1,
      rows: 1,

      responsive: [ {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3
        }
      }, {
        breakpoint: 778,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2
        }
      },
        {
          breakpoint: 575,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
          }
        }]
    }
  
    return (
      <div className='site-main'>
        <Header/>
        {/* PageHeader */}
        <PageHeader title='Who We Are' breadcrumb='About' />
        {/* PageHeader end */}
        {/* job-list-section */}
        <OurStory />
    
        {/* job-list end */}
        {/* padding_zero-section */}
        <section className='ttm-row padding_bottom_zero-section bg-layer-equal-height mt_30 mt_lg-60 clearfix'>
          <div className='container p-0'>
            <div className='row g-0'>
              <div className='col-lg-8'>
                <div className='ttm-bg ttm-bg ttm-col-bgimage-yes col-bg-img-five ttm-left-span spacing-3'>
                  <div className='ttm-col-wrapper-bg-layer ttm-bg-layer' style={{ backgroundImage: 'url(images/bg-image/col-bgimage-5.jpg)' }}>
                    <div className='ttm-col-wrapper-bg-layer-inner'></div>
                  </div>
                  <div className='layer-content'>
                    {/* section title  */}
                    <div className='section-title'>
                      <div className='title-header'>
                        <h3>WHO WE ARE</h3>
                        <h2 className='title'>Empowering Maritime Excellence</h2>
                      </div>
                      <div className='title-desc'>
                        <p>
                        At Atlas , we recognise the critical role that skilled offshore crew play in the success of maritime operations. Therefore, we leverage cutting-edge technology and a vast network of industry connections to identify, recruit, and deploy the best talent available. Our rigorous screening processes and comprehensive training programs guarantee that every individual we place possesses the requisite skills, qualifications, and certifications to excel in their roles.
                        </p>
                      </div>
                    </div>
                    {/* section title end  */}
                    <div className='row g-0 d-inline-flex ttm-vertical_sep'>
                      <div className='col'>
                        <div className='d-inline-block pt-10 pb-10 pr-60 pr-md-30'>
                          <img className='img-fluid' src='images/About/1.png' alt='award1' />
                          <div className='pt-20 text-center'>
                            <h6>Global <br></br> Talent Sourcing</h6>
                          </div>
                        </div>
                      </div>
                      <div className='col'>
                        <div className='d-inline-block pt-10 pb-10 pl-60 pl-md-30'>
                          <img className='img-fluid' src='images/About/2.png' alt='award2' />
                          <div className='pt-20 text-center'>
                            <h6>Commitment <br></br> to Excellence</h6>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className='pt-20 pb-90 pb-lg-0'>
                      <p>
                        {/* We have won 27 more awards in our career. <a href={process.env.PUBLIC_URL + '/'}><b><u>View All Awards</u></b></a> */}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className='col-lg-4 col-md-12'>
                {/* col-img-img-six */}
                <div className='ttm-bg ttm-col-bgimage-yes col-bg-img-six ttm-right-span z-index-2 ml_120 ml_lg-0'>
                  <div className='ttm-col-wrapper-bg-layer ttm-bg-layer' style={{ backgroundImage: 'url(images/About/aboutbg3.png)' }}></div>
                  <div className='layer-content'>
                  </div>
                  <img className='img-fluid col-bg-img-res' src='images/About/About3.png' alt='bgimage' />
                </div>
                {/* col-img-bg-img-six end */}
              </div>
            </div>
            {/* row end */}
          </div>
        </section>
        {/* padding_zero-section end */}
        {/* team-section */}
        <section className='ttm-row team-section bg-theme-GreyColor clearfix'>
          <div className='container'>
            {/* row */}
            <div className='row'>
              <div className='col-lg-12'>
                {/* section title */}
                <div className='section-title title-style-center_text'>
                  <div className='title-header'>
                    {/* <h3>Experts <span className='text-theme-SkinColor'>team!</span></h3> */}
                    <h2 className='title'>GET TO KNOW US</h2>
                  </div>
                </div>
                {/* section title end */}
              </div>
            </div>
            {/* row end */}
            {/* Slider */}
            <Slider
              className='row slick_slider mb_10'
              {...slick_slider}
              slidesToShow={3}
              arrows={false}
              autoplay={false}>
              <div className='col-md-12'>
                {/* featured-imagebox */}
                <div className='featured-imagebox featured-imagebox-team style1'>
                  {/* featured-thumbnail */}
                  <div className='featured-thumbnail'>
                    <img className='img-fluid'  src='images/founder/Kevin.png' />
                  </div>
                  {/* featured-thumbnail end */}
                  {/* <div className='featured-media ttm-media-link'>
                    <div className='media-block'>
                      <ul className='social-icons list-inline'>
                        <li>
                          <a href={process.env.PUBLIC_URL + '/'} rel='noopener' aria-label='facebook'><i className='ti ti-facebook'></i></a>
                        </li>
                        <li>
                          <a href={process.env.PUBLIC_URL + '/'} rel='noopener' aria-label='twitter'><i className='ti ti-twitter-alt'></i></a>
                        </li>
                        <li>
                          <a href={process.env.PUBLIC_URL + '/'} rel='noopener' aria-label='google'><i className='ti ti-google'></i></a>
                        </li>
                        <li>
                          <a href={process.env.PUBLIC_URL + '/'} rel='noopener' aria-label='linkedin'><i className='ti ti-linkedin'></i></a>
                        </li>
                      </ul>
                    </div>
                  </div> */}
                  <div className='featured-content'>
                    <div className='featured-title'>
                      <h3><a onClick={() => window.location.href = '#client_section'}>KEVIN DIAS</a></h3>
                    </div>
                    <div className='featured-desc'>
                      Our Founder
                     </div>
                  </div>
                </div>
                {/* featured-imagebox end */}
              </div>
              <div className='col-md-12'>
                {/* featured-imagebox */}
                <div className='featured-imagebox featured-imagebox-team style1'>
                  {/* featured-thumbnail */}
                  <div className='featured-thumbnail'>
                    <img className='img-fluid'  src='images/founder/Renvin.png' />
                  </div>
                  {/* featured-thumbnail end */}
                  {/* <div className='featured-media ttm-media-link'>
                    <div className='media-block'>
                      <ul className='social-icons list-inline'>
                        <li>
                          <a href={process.env.PUBLIC_URL + '/'} rel='noopener' aria-label='facebook'><i className='ti ti-facebook'></i></a>
                        </li>
                        <li>
                          <a href={process.env.PUBLIC_URL + '/'} rel='noopener' aria-label='twitter'><i className='ti ti-twitter-alt'></i></a>
                        </li>
                        <li>
                          <a href={process.env.PUBLIC_URL + '/'} rel='noopener' aria-label='google'><i className='ti ti-google'></i></a>
                        </li>
                        <li>
                          <a href={process.env.PUBLIC_URL + '/'} rel='noopener' aria-label='linkedin'><i className='ti ti-linkedin'></i></a>
                        </li>
                      </ul>
                    </div>
                  </div> */}
                  <div className='featured-content'>
                    <div className='featured-title'>
                      <h3><a onClick={() => window.location.href = '#client_section'}>RENVIN DIAS</a></h3>
                    </div>
                    <div className='featured-desc'>
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                <p>
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                       Our Director
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                      </p>
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                    </div>
                  </div>
                </div>
                {/* featured-imagebox end */}
              </div>
              <div className='col-md-12'>
                {/* featured-imagebox */}
                <div className='featured-imagebox featured-imagebox-team style1'>
                  {/* featured-thumbnail */}
                  <div className='featured-thumbnail'>
                    <img className='img-fluid'  src='images/founder/Revinka.png' />
                  </div>
                  {/* featured-thumbnail end */}
                  {/* <div className='featured-media ttm-media-link'>
                    <div className='media-block'>
                      <ul className='social-icons list-inline'>
                        <li>
                          <a href={process.env.PUBLIC_URL + '/'} rel='noopener' aria-label='facebook'><i className='ti ti-facebook'></i></a>
                        </li>
                        <li>
                          <a href={process.env.PUBLIC_URL + '/'} rel='noopener' aria-label='twitter'><i className='ti ti-twitter-alt'></i></a>
                        </li>
                        <li>
                          <a href={process.env.PUBLIC_URL + '/'} rel='noopener' aria-label='google'><i className='ti ti-google'></i></a>
                        </li>
                        <li>
                          <a href={process.env.PUBLIC_URL + '/'} rel='noopener' aria-label='linkedin'><i className='ti ti-linkedin'></i></a>
                        </li>
                      </ul>
                    </div>
                  </div> */}
                  <div className='featured-content'>
                    <div className='featured-title'>
                      <h3><a onClick={() => window.location.href = '#client_section'}>REVINKA DIAS</a></h3>
                    </div>
                    <div className='featured-desc'>
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                              <p>
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                Our Director
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                              </p>
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                            </div>
                  </div>
                </div>
                {/* featured-imagebox end */}
              </div>
              {/* <div className='col-md-12'>

                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                    <div className='featured-imagebox featured-imagebox-team style1'>

                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                      <div className='featured-thumbnail'>
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                        <img className='img-fluid' src='https://via.placeholder.com/600x800?text=600x600+team-img04-600x800.jpg' />
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                      </div>

                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                      <div className='featured-media ttm-media-link'>
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                        <div className='media-block'>
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                          <ul className='social-icons list-inline'>
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                            <li>
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                              <a href={process.env.PUBLIC_URL + '/'} rel='noopener' aria-label='facebook'><i className='ti ti-facebook'></i></a>
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                            </li>
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                            <li>
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                              <a href={process.env.PUBLIC_URL + '/'} rel='noopener' aria-label='twitter'><i className='ti ti-twitter-alt'></i></a>
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                            </li>
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                            <li>
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                              <a href={process.env.PUBLIC_URL + '/'} rel='noopener' aria-label='google'><i className='ti ti-google'></i></a>
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                            </li>
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                            <li>
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                              <a href={process.env.PUBLIC_URL + '/'} rel='noopener' aria-label='linkedin'><i className='ti ti-linkedin'></i></a>
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                            </li>
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                          </ul>
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                        </div>
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                      </div>
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                      <div className='featured-content'>
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                        <div className='featured-title'>
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                          <h3><a href={process.env.PUBLIC_URL + '/'}>Melika Fonals</a></h3>
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                        </div>
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                        <div className='featured-desc'>
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                          <p>
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                            Engineer
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                          </p>
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                        </div>
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                      </div>
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                    </div>

                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                  </div> */}
              {/* <div className='col-md-12'>

                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                    <div className='featured-imagebox featured-imagebox-team style1'>

                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                      <div className='featured-thumbnail'>
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                        <img className='img-fluid' src='https://via.placeholder.com/600x800?text=600x600+team-img02-600x800.jpg' />
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                      </div>

                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                      <div className='featured-media ttm-media-link'>
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                        <div className='media-block'>
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                          <ul className='social-icons list-inline'>
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                            <li>
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                              <a href={process.env.PUBLIC_URL + '/'} rel='noopener' aria-label='facebook'><i className='ti ti-facebook'></i></a>
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                            </li>
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                            <li>
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                              <a href={process.env.PUBLIC_URL + '/'} rel='noopener' aria-label='twitter'><i className='ti ti-twitter-alt'></i></a>
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                            </li>
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                            <li>
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                              <a href={process.env.PUBLIC_URL + '/'} rel='noopener' aria-label='google'><i className='ti ti-google'></i></a>
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                            </li>
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                            <li>
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                              <a href={process.env.PUBLIC_URL + '/'} rel='noopener' aria-label='linkedin'><i className='ti ti-linkedin'></i></a>
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                            </li>
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                          </ul>
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                        </div>
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                      </div>
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                      <div className='featured-content'>
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                        <div className='featured-title'>
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                          <h3><a href={process.env.PUBLIC_URL + '/'}>Jennifer Garcia</a></h3>
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                        </div>
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                        <div className='featured-desc'>
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                          <p>
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                            Engineer
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                          </p>
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                        </div>
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                      </div>
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                    </div>

                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                  </div> */}
              {/* <div className='col-md-12'>

                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                    <div className='featured-imagebox featured-imagebox-team style1'>

                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                      <div className='featured-thumbnail'>
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                        <img className='img-fluid' src='https://via.placeholder.com/600x800?text=600x600+team-img04-600x800.jpg' />
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                      </div>

                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                      <div className='featured-media ttm-media-link'>
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                        <div className='media-block'>
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                          <ul className='social-icons list-inline'>
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                            <li>
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                              <a href={process.env.PUBLIC_URL + '/'} rel='noopener' aria-label='facebook'><i className='ti ti-facebook'></i></a>
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                            </li>
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                            <li>
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                              <a href={process.env.PUBLIC_URL + '/'} rel='noopener' aria-label='twitter'><i className='ti ti-twitter-alt'></i></a>
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                            </li>
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                            <li>
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                              <a href={process.env.PUBLIC_URL + '/'} rel='noopener' aria-label='google'><i className='ti ti-google'></i></a>
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                            </li>
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                            <li>
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                              <a href={process.env.PUBLIC_URL + '/'} rel='noopener' aria-label='linkedin'><i className='ti ti-linkedin'></i></a>
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                            </li>
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                          </ul>
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                        </div>
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                      </div>
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                      <div className='featured-content'>
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                        <div className='featured-title'>
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                          <h3><a href={process.env.PUBLIC_URL + '/'}>Melika Fonals</a></h3>
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                        </div>
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                        <div className='featured-desc'>
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                          <p>
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                            Engineer
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                          </p>
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                        </div>
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                      </div>
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                    </div>

                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                  </div> */}
            </Slider>
            {/* Slider end */}
          </div>
        </section>
        {/* team-section end */}
        {/* about-section */}
        <section className='ttm-row about-section clearfix'>
          <div className='container'>
            {/* row */}
            <div className='row'>
              <div className='col-lg-6'>
                {/* section title */}
                <div className='section-title'>
                  <div className='title-header'>
                    <h3>Why Choose <span className='text-theme-SkinColor'>Atlas?</span></h3>
                    <h2 className='title'>Tailored Recruitment Solutions</h2>
                  </div>
                  <div className='title-desc'>
                    <p>
                      We understand the unique demands of the Offshore, Maritime, White & Blue Collar and Construction sectors and tailor our recruitment solutions accordingly. Services
                      we offer are Contract Recruitment, Integrated Crew Management, Permanent Recruitment Training & Competency Management Payroll Services Travel & Logistics
                      Management
                    </p>
                  </div>
                </div>
                <div className='section-title'>
                  <div className='title-header'>
                    <h2 className='title'>Excellence as Standard</h2>
                  </div>
                  <div className='title-desc'>
                    <p>
                      At Atlas, we firmly believe that excellence is the driving force behind our services. This belief fuels our proficiency in global research and analysis, enabling
                      us to source experienced and knowledgeable candidates efficiently.
                    </p>
                  </div>
                </div>
                {/* section title end */}
                <div className='ttm-tabs ttm-tab-style-01'>
                  <Tabs>
                    <TabList className='tabs'>
                      <Tab className='tab'>
                        <a className='tab-1' tabIndex='0'>Our Mision</a>
                      </Tab>
                      <Tab className='tab'>
                        <a className='tab-2' tabIndex='0'>Our Vision</a>
                      </Tab>
                      <Tab className='tab'>
                        <a className='tab-3' tabIndex='0'>Core Values</a>
                      </Tab>
                      <Tab className='tab'>
                        <a className='tab-4' tabIndex='0'>Our Team</a>
                      </Tab>
                    </TabList>
                    <div className='content-tab'>
                      <TabPanel>
                        <div className='row'>
                          <div className='col-lg-4 col-md-3 col-sm-4'>
                            <img
                              className='img-fluid alignleft'
                              height='200'
                              width='200'
                              src='images/About/MissionVisionValue/1.png'
                              alt='image' />
                          </div>
                          <div className='col-lg-8 col-md-9 col-sm-8'>
                            <div className='pt-15'>
                              <p>
                                Connecting Maritime Excellence Worldwide
                              </p>
                              <ul className='ttm-list ttm-list-style-icon ttm-list-icon-color-skincolor'>
                                <li>
                                  <i className='far fa-check-circle'></i>
                                  <div className='ttm-list-li-content'>
                                    Facilitating seamless talent acquisition for maritime enterprises.
                                  </div>
                                </li>
                                <li>
                                  <i className='far fa-check-circle'></i>
                                  <div className='ttm-list-li-content'>
                                    Fostering industry growth through strategic staffing solutions.
                                  </div>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </TabPanel>
                      <TabPanel>
                        <div className='row'>
                          <div className='col-lg-4 col-md-3 col-sm-4'>
                            <img
                              className='img-fluid alignleft'
                              height='200'
                              width='200'
                              src='images/About/MissionVisionValue/2.png'
                              alt='image' />
                          </div>
                          <div className='col-lg-8 col-md-9 col-sm-8'>
                            <div className='pt-15'>
                              <p>
                                Leading the Maritime Recruitment Revolution
                              </p>
                              <ul className='ttm-list ttm-list-style-icon ttm-list-icon-color-skincolor'>
                                <li>
                                  <i className='far fa-check-circle'></i>
                                  <div className='ttm-list-li-content'>
                                    Pioneering innovative recruitment practices in the maritime sector.
                                  </div>
                                </li>
                                <li>
                                  <i className='far fa-check-circle'></i>
                                  <div className='ttm-list-li-content'>
                                    Setting new standards for excellence and client satisfaction.
                                  </div>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </TabPanel>
                      <TabPanel>
                        <div className='row'>
                          <div className='col-lg-4 col-md-3 col-sm-4'>
                            <img
                              className='img-fluid alignleft'
                              height='200'
                              width='200'
                              src='images/About/MissionVisionValue/3.png'
                              alt='image' />
                          </div>
                          <div className='col-lg-8 col-md-9 col-sm-8'>
                            <div className='pt-15'>
                              <p>
                                Integrity and Excellence in Maritime Recruitment
                              </p>
                              <ul className='ttm-list ttm-list-style-icon ttm-list-icon-color-skincolor'>
                                <li>
                                  <i className='far fa-check-circle'></i>
                                  <div className='ttm-list-li-content'>
                                    Upholding integrity in all partnerships and interactions.
                                  </div>
                                </li>
                                <li>
                                  <i className='far fa-check-circle'></i>
                                  <div className='ttm-list-li-content'>
                                    Striving for excellence through continuous improvement and dedication.
                                  </div>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </TabPanel>
                      <TabPanel>
                        <div className='row'>
                          <div className='col-lg-4 col-md-3 col-sm-4'>
                            <img
                              className='img-fluid alignleft'
                              height='200'
                              width='200'
                              src='images/About/MissionVisionValue/4.png'
                              alt='image' />
                          </div>
                          <div className='col-lg-8 col-md-9 col-sm-8'>
                            <div className='pt-15'>
                              {/* <p>
                              
                              </p> */}
                              <ul className='ttm-list ttm-list-style-icon ttm-list-icon-color-skincolor'>
                              <li>
                                  {/* <i className='far fa-check-circle'></i> */}
                                  <div className='ttm-list-li-content'>
                                  Our dedicated professionals from each department form a cohesive team committed to delivering high-quality manpower solutions and unparalleled customer service.
                                  </div>
                                </li>
                                <li>
                                  <i className='far fa-check-circle'></i>
                                  <div className='ttm-list-li-content'>
                                  Human Resources: Our HR team ensures seamless recruitment and nurturing of top talent, fostering a productive and safe work environment for clients and candidates.
                                  </div>
                                </li>
                                <li>
                                  <i className='far fa-check-circle'></i>
                                  <div className='ttm-list-li-content'>
                                  HSSQE: The HSSQE (Health, Safety, Security, Quality, and Environment) team ensures adherence to stringent standards, safeguarding the well-being of our workforce and maintaining excellence in service delivery. They implement rigorous protocols, conduct thorough risk assessments, and foster a culture of compliance to ensure the highest levels of safety, quality, and environmental responsibility across all operations.
                                  </div>
                                </li>
                                <li>
                                  <i className='far fa-check-circle'></i>
                                  <div className='ttm-list-li-content'>
                                  Operations & Logistics: Our logistics experts optimize manpower deployment, ensuring timely and efficient service delivery to meet client demands 24/7.
                                  </div>
                                </li>
                                <li>
                                  <i className='far fa-check-circle'></i>
                                  <div className='ttm-list-li-content'>
                                  Business Development: Our Marketing team proactively engages with clients, understanding their unique needs and offering tailored solutions to drive mutual success. They strategically promote our recruitment services, leveraging creativity and industry insights to connect top talent with leading employers in the market.
                                  </div>
                                </li>
                                <li>
                                  <i className='far fa-check-circle'></i>
                                  <div className='ttm-list-li-content'>
                                  Finance and Payroll: Our finance team maintains transparent financial records and processes payroll with accuracy, ensuring confidence to our clients and employees.
                                  </div>
                                </li>
                                <li>
                                  <i className='far fa-check-circle'></i>
                                  <div className='ttm-list-li-content'>
                                  Managerial Positions: Our experienced managers drive strategic initiatives, foster client relationships, and empower teams to achieve exceptional results.
                                  </div>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </TabPanel>
                    </div>
                  </Tabs>
                </div>
              </div>
              <div className='col-lg-6'>
                <div className='mr-30 mt-30 mt-lg-60 mb-lg-30'>
                  <div className='d-flex'>
                    <img src='images/About/about1.png' className='img-fluid' alt='single_05' />
                    <div className='flex-basis-auto ml_180 mr_30 mt_30 z-index_1'>
                      <img src='images/dot-pattern.png' className='img-fluid' alt='dot-pattern' />
                    </div>
                    <div className='d-flex justify-content-end flex-column ml_180 mr_30 mb_35 z-index_1'>
                      <img src='images/dot-pattern.png' className='img-fluid' alt='dot-pattern' />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* row end */}
          </div>
        </section>
        {/* about-section end */}
        {/* About Founder */}
        <div id='client_section'>
        <ClientSection
                  sliderElements={sliderElements1}
          title=''
          subtitle='GET TO KNOW US'
          bgImage={bgCompany} />
          </div>
        {/* padding_bottom_zero-section */}
        <section className='ttm-row padding_bottom_zero-section bg-img6 bg-theme-DarkColor ttm-bg ttm-bgimage-yes text-theme-WhiteColor clearfix' style={{ backgroundImage: 'url("images/About/about2.png")' }}>
          <div className='ttm-row-wrapper-bg-layer ttm-bg-layer bg-theme-DarkColor'></div>
          <div className='container'>
            {/* row */}
            <div className='row'>
              <div className='col-lg-12'>
                <div className='pt-80 pt-md-30 text-center'>
                  <div className='ttm-play-icon-btn'>
                    <div className='ttm-play-icon-animation'>
                      <a>
                        <div className='ttm-icon ttm-icon_element-fill ttm-icon_element-color-white ttm-icon_element-size-md ttm-icon_element-style-rounded'>
                          <Video className='fa fa-play text-theme-SkinColor' /> </div>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div className='col-xl-5 col-lg-6 col-md-7'>
                <div className='d-flex pt-80 pt-md-80'>
                  <div className='p-15 w-100 bg-theme-SkinColor text-center'>
                    {/* fid */}
                    {/* <div className='ttm-fid inside ttm-fid-view-topicon'>
                      <div className='ttm-fid-contents'>
                        <h4><CountUp
                              start={0}
                              end={1458}
                              duration={20}
                              delay={2} /></h4>
                        <h3 className='ttm-fid-title'>Completed Projects</h3>
                      </div>
                    </div> */}
                    {/* fid end */}
                  </div>
                  <div className='p-15 w-100 bg-theme-DarkColor text-center'>
                    {/* fid */}
                    {/* <div className='ttm-fid inside ttm-fid-view-topicon'>
                      <div className='ttm-fid-contents'>
                        <h4><CountUp
                              start={0}
                              end={256}
                              duration={20}
                              delay={2} /> +</h4>
                        <h3 className='ttm-fid-title'>Working Hours!</h3>
                      </div>
                    </div> */}
                    {/* fid end */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* padding_bottom_zero-section end */}
        {/* testimonial-section */}
        <section className='ttm-row testimonial-section clearfix'>
          <div className='container'>
            <div className='row'>
              <div className='col-lg-11'>
                {/* section title */}
                <div className='section-title style2'>
                  <div className='title-header'>
                    {/* <h3>Happy Employee</h3> */}
                    <h2 className='title'>Employee Testimonials</h2>
                  </div>
                  <div className='title-desc'>
                    <p>
                      Our proven track record, built over the years, showcases our  <span className='text-theme-SkinColor'>steadfast commitment and expertise </span> in our field. We take pride in the milestones we've achieved, which reflect our dedication, hard work, and professional skill.
                    </p>
                  </div>
                </div>
                {/* section title end */}
              </div>
            </div>
            <Slider
              className='row slick_slider slick-arrows-style2'
              {...slick_slider}
              slidesToShow={3}
              arrows={true}>
              <div className='col-lg-12'>
                {/* testimonials */}
                <div className='testimonials ttm-testimonial-box-view-style1'>
                  <div className='testimonial-content'>
                    <blockquote className='testimonial-text'>
                      I have been working with Atlas since 2017. I started as a Roustabout and have been able to grow my career with experience and hard work. I am currently appointed
                      as a Derrickman on Hakuryu-10. Atlas has worked tirelessly on the expansion and betterment of their company and crew. Their work and insights on
                      the crew's particular interests are widely recognized which makes working an experience to remember! I wish them much success in the future! "
                    </blockquote>
                    {/* <div className='star-ratings'>
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                      <ul className='rating'>
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                        <li className='active'>
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                          <i className='fa fa-star'></i>
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                        </li>
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                        <li className='active'>
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                          <i className='fa fa-star'></i>
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                        </li>
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                        <li className='active'>
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                          <i className='fa fa-star'></i>
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                        </li>
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                        <li className='active'>
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                          <i className='fa fa-star'></i>
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                        </li>
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                        <li className='active'>
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                          <i className='fa fa-star'></i>
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                        </li>
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                      </ul>
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                    </div> */}
                  </div>
                  <div className='testimonial-bottom'>
                    <div className='testimonial-avatar'>
                      <div className='testimonial-img'>
                        <img className='img-fluid' src='images/Team/testomonial.svg' alt='testimonial-img' />
                      </div>
                    </div>
                    <div className='testimonial-caption'>
                      <label>
                      Jaron Serpis 
                      </label>
                      <h3> Derrickman</h3>
                    </div>
                  </div>
                </div>
                {/* testimonials end */}
              </div>
              <div className='col-lg-12'>
                {/* testimonials */}
                <div className='testimonials ttm-testimonial-box-view-style1'>
                  <div className='testimonial-content'>
                    <blockquote className='testimonial-text'>
                      I joined Atlas in 2017 and have had an enriching four and a six-year journey. The highlight of which has been how Atlas looks out for the general well-being of its
                      crew. They are always around to provide support to the best of their capacity when the need arises.
                    </blockquote>
                    {/* <div className='star-ratings'>
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                      <ul className='rating'>
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                        <li className='active'>
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                          <i className='fa fa-star'></i>
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                        </li>
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                        <li className='active'>
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                          <i className='fa fa-star'></i>
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                        </li>
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                        <li className='active'>
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                          <i className='fa fa-star'></i>
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                        </li>
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                        <li className='active'>
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                          <i className='fa fa-star'></i>
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                        </li>
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                        <li className='active'>
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                          <i className='fa fa-star'></i>
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                        </li>
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                      </ul>
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                    </div> */}
                  </div>
                  <div className='testimonial-bottom'>
                    <div className='testimonial-avatar'>
                      <div className='testimonial-img'>
                        <img className='img-fluid' src='images/Team/testomonial.svg' alt='testimonial-img' />
                      </div>
                    </div>
                    <div className='testimonial-caption'>
                      <label>
                      Clifford Kaskar 
                      </label>
                      <h3> Crane Operator</h3>
                    </div>
                  </div>
                </div>
                {/* testimonials end */}
              </div>
              <div className='col-lg-12'>
                {/* testimonials */}
                <div className='testimonials ttm-testimonial-box-view-style1'>
                  <div className='testimonial-content'>
                    <blockquote className='testimonial-text'>
                      Working with the team at Atlas has been a refreshing experience both professionally and personally. As a company, Atlas has always put people and safety first as
                      a priority. Getting it right the first time every time attitude by the Atlas team has been a real driving factor in helping the offshore crew carry
                      out their roles and responsibilities seamlessly and come back home to their loving families on time every time. I would like to wish the Atlas team
                      all the very best in their future endeavors and everyone of us are really proud to be a part of Team Atlas. God bless and good luck.
                    </blockquote>
                    {/* <div className='star-ratings'>
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                      <ul className='rating'>
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                        <li className='active'>
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                          <i className='fa fa-star'></i>
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                        </li>
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                        <li className='active'>
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                          <i className='fa fa-star'></i>
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                        </li>
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                        <li className='active'>
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                          <i className='fa fa-star'></i>
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                        </li>
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                        <li className='active'>
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                          <i className='fa fa-star'></i>
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                        </li>
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                        <li className='active'>
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                          <i className='fa fa-star'></i>
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                        </li>
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                      </ul>
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                    </div> */}
                  </div>
                  <div className='testimonial-bottom'>
                    <div className='testimonial-avatar'>
                      <div className='testimonial-img'>
                        <img className='img-fluid' src='images/Team/testomonial.svg' alt='testimonial-img' />
                      </div>
                    </div>
                    <div className='testimonial-caption'>
                      <label>
                      Robin Monteiro 
                      </label>
                      <h3> Roughneck</h3>
                    </div>
                  </div>
                </div>
                {/* testimonials end */}
              </div>
              <div className='col-lg-12'>
                {/* testimonials */}
                <div className='testimonials ttm-testimonial-box-view-style1'>
                  <div className='testimonial-content'>
                    <blockquote className='testimonial-text'>
                      I joined Atlas in 2017 and have had an enriching four and a Six year journey. The highlight of which has been how Atlas looks out for the general well-being of it's
                      crew. They are always around to provide support to the best of their capacity when the need arises.
                    </blockquote>
                    {/* <div className='star-ratings'>
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                      <ul className='rating'>
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                        <li className='active'>
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                          <i className='fa fa-star'></i>
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                        </li>
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                        <li className='active'>
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                          <i className='fa fa-star'></i>
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                        </li>
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                        <li className='active'>
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                          <i className='fa fa-star'></i>
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                        </li>
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                        <li className='active'>
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                          <i className='fa fa-star'></i>
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                        </li>
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                        <li className='active'>
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                          <i className='fa fa-star'></i>
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                        </li>
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                      </ul>
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                    </div> */}
                  </div>
                  <div className='testimonial-bottom'>
                    <div className='testimonial-avatar'>
                      <div className='testimonial-img'>
                        <img className='img-fluid' src='images/Team/testomonial.svg' alt='testimonial-img' />
                      </div>
                    </div>
                    <div className='testimonial-caption'>
                      <label>
                      Keith Rodrigues
                      </label>
                      <h3>  Pumpman</h3>
                    </div>
                  </div>
                </div>
                {/* testimonials end */}
              </div>
              <div className='col-lg-12'>
                {/* testimonials */}
                <div className='testimonials ttm-testimonial-box-view-style1'>
                  <div className='testimonial-content'>
                    <blockquote className='testimonial-text'>
                      Working with the team at Atlas has been a refreshing experience both professionally and personally. As a company Atlas has always put people and safety first as
                      a priority. Getting it right the first time every time attitude by the Atlas team has been a real driving factor in helping the offshore crew carry
                      out their roles and responsibilities seamlessly and come back home to their loving families on time every time.
                      <br/> I would like to wish the Atlas team all the very best in their future endeavours and everyone of us are really proud to be a part of Team Atlas.
                      God bless and good luck.
                    </blockquote>
                    {/* <div className='star-ratings'>
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                      <ul className='rating'>
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                        <li className='active'>
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                          <i className='fa fa-star'></i>
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                        </li>
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                        <li className='active'>
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                          <i className='fa fa-star'></i>
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                        </li>
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                        <li className='active'>
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                          <i className='fa fa-star'></i>
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                        </li>
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                        <li className='active'>
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                          <i className='fa fa-star'></i>
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                        </li>
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                        <li className='active'>
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                          <i className='fa fa-star'></i>
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                        </li>
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                      </ul>
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                    </div> */}
                  </div>
                  <div className='testimonial-bottom'>
                    <div className='testimonial-avatar'>
                      <div className='testimonial-img'>
                        <img className='img-fluid' src='images/Team/testomonial.svg' alt='testimonial-img' />
                      </div>
                    </div>
                    <div className='testimonial-caption'>
                      <label>
                        Dominic McGuinness
                      </label>
                      <h3>Barge Master</h3>
                    </div>
                  </div>
                </div>
                {/* testimonials end */}
              </div>

              <div className='col-lg-12'>
                {/* testimonials */}
                <div className='testimonials ttm-testimonial-box-view-style1'>
                  <div className='testimonial-content'>
                    <blockquote className='testimonial-text'>
                    My journey with Atlas has been really great. The staff and management were very cooperative and helpful throughout the journey. Atlas has always given us wonderful opportunities to explore ourselves. Whenever any problems arose, the team always responded right away. To conclude, all I can say it was a great experience. Thank you. I would like to wish the Atlas team all the very best in their future endeavours and everyone of us are really proud to be a part of Team Atlas. God bless and good luck.
                    </blockquote>
                  </div>
                  <div className='testimonial-bottom'>
                    <div className='testimonial-avatar'>
                      <div className='testimonial-img'>
                        <img className='img-fluid' src='images/Team/testomonial.svg' alt='testimonial-img' />
                      </div>
                    </div>
                    <div className='testimonial-caption'>
                      <label>
                      Davinder Singh
                      </label>
                      <h3>Crane Operator</h3>
                    </div>
                  </div>
                </div>
                {/* testimonials end */}
              </div>
              <div className='col-lg-12'>
                {/* testimonials */}
                <div className='testimonials ttm-testimonial-box-view-style1'>
                  <div className='testimonial-content'>
                    <blockquote className='testimonial-text'>
                    Atlas is one of the best companies in Qatar, a company that provides good pay scale to employees, very good services, and good quality. Thanks.
                    </blockquote>
                  </div>
                  <div className='testimonial-bottom'>
                    <div className='testimonial-avatar'>
                      <div className='testimonial-img'>
                        <img className='img-fluid' src='images/Team/testomonial.svg' alt='testimonial-img' />
                      </div>
                    </div>
                    <div className='testimonial-caption'>
                      <label>
                      Rahison Raj
                      </label>
                      <h3>Rigging Supervisor</h3>
                    </div>
                  </div>
                </div>
                {/* testimonials end */}
              </div>
              <div className='col-lg-12'>
                {/* testimonials */}
                <div className='testimonials ttm-testimonial-box-view-style1'>
                  <div className='testimonial-content'>
                    <blockquote className='testimonial-text'>
                    Among the subcontractors I've worked with in Qatar, I think Atlas is the best. Their attention to details, staffs to management relationships, is top-notch.
The approaches from the customers care and HR team won my trust, especially my focal point of contact who always paid full attention to complaints and always offers solutions to whatever our challenges may be.
                    </blockquote>
                  </div>
                  <div className='testimonial-bottom'>
                    <div className='testimonial-avatar'>
                      <div className='testimonial-img'>
                        <img className='img-fluid' src='images/Team/testomonial.svg' alt='testimonial-img' />
                      </div>
                    </div>
                    <div className='testimonial-caption'>
                      <label>
                      Ismail Omikunle
                      </label>
                      <h3>Lifting Supervisor</h3>
                    </div>
                  </div>
                </div>
                {/* testimonials end */}
              </div>
              <div className='col-lg-12'>
                {/* testimonials */}
                <div className='testimonials ttm-testimonial-box-view-style1'>
                  <div className='testimonial-content'>
                    <blockquote className='testimonial-text'>
                    I have been working with Telford through ATLAS, though for short period of time. I have great experience with them. Entire team is incredibly supportive and cooperative during hiring and onboarding process. They are adept and always available to communicate in case of any issues.
Thank you for support and incredible experience of working with Atlas. Looking forward to receiving the same experience in future. Thank you Again!
                    </blockquote>
                  </div>
                  <div className='testimonial-bottom'>
                    <div className='testimonial-avatar'>
                      <div className='testimonial-img'>
                        <img className='img-fluid' src='images/Team/testomonial.svg' alt='testimonial-img' />
                      </div>
                    </div>
                    <div className='testimonial-caption'>
                      <label>
                      Dr. Pankaj Shende
                      </label>
                      <h3>Doctor</h3>
                    </div>
                  </div>
                </div>
                {/* testimonials end */}
              </div>
              <div className='col-lg-12'>
                {/* testimonials */}
                <div className='testimonials ttm-testimonial-box-view-style1'>
                  <div className='testimonial-content'>
                    <blockquote className='testimonial-text'>
                    I have been working with Atlas India since its inception days. I had the most rewarding & best learning years of my career with this company. If anyone wants to work in a great environment and culture- this is the right place.
This organization embraces to new ideas and its open to growth opportunities, the company values its employees and takes care of its employees as their own family member and it really matters to me personally.
                    </blockquote>
                  </div>
                  <div className='testimonial-bottom'>
                    <div className='testimonial-avatar'>
                      <div className='testimonial-img'>
                        <img className='img-fluid' src='images/Team/testomonial.svg' alt='testimonial-img' />
                      </div>
                    </div>
                    <div className='testimonial-caption'>
                      <label>
                      Sunil Dsouza
                      </label>
                      <h3>Radio Operator</h3>
                    </div>
                  </div>
                </div>
                {/* testimonials end */}
              </div>
              <div className='col-lg-12'>
                {/* testimonials */}
                <div className='testimonials ttm-testimonial-box-view-style1'>
                  <div className='testimonial-content'>
                    <blockquote className='testimonial-text'>
                    Atlas has shown well understanding and cooperation towards the employees' requests which is indeed appreciated.  I have learnt a lot during my service period with Atlas.  Nevertheless, there seems to still be room for daily transportation coordination. Overall working with the Atlas is a pleasurable experience.  
                    </blockquote>
                  </div>
                  <div className='testimonial-bottom'>
                    <div className='testimonial-avatar'>
                      <div className='testimonial-img'>
                        <img className='img-fluid' src='images/Team/testomonial.svg' alt='testimonial-img' />
                      </div>
                    </div>
                    <div className='testimonial-caption'>
                      <label>
                      Prem Kumar
                      </label>
                      <h3>Safety Officer</h3>
                    </div>
                  </div>
                </div>
                {/* testimonials end */}
              </div>
              <div className='col-lg-12'>
                {/* testimonials */}
                <div className='testimonials ttm-testimonial-box-view-style1'>
                  <div className='testimonial-content'>
                    <blockquote className='testimonial-text'>
                    Atlas gave me an opportunity to work as a Safety Officer at AG Middle East, I am very thankful for this opportunity. In this short period of time, they assisted be with the best services they could offer. I look forward to re-joining Atlas work force in the near future. I really appreciate their office staff who were always available for my concerns.
                    </blockquote>
                  </div>
                  <div className='testimonial-bottom'>
                    <div className='testimonial-avatar'>
                      <div className='testimonial-img'>
                        <img className='img-fluid' src='images/Team/testomonial.svg' alt='testimonial-img' />
                      </div>
                    </div>
                    <div className='testimonial-caption'>
                      <label>
                      Jesus Saligumba
                      </label>
                      <h3>Safety Officer</h3>
                    </div>
                  </div>
                </div>
                {/* testimonials end */}
              </div>
              <div className='col-lg-12'>
                {/* testimonials */}
                <div className='testimonials ttm-testimonial-box-view-style1'>
                  <div className='testimonial-content'>
                    <blockquote className='testimonial-text'>
                    I, Bhawani Sharan working as an Excavator Operator in Saudi Arabia through Atlas. My colleagues and I are very thankful for the support and smooth operations by Atlas in mobilising me and my team to Saudi. The working conditions are good and all are happy with this employment. Thank you for Atlas support and cooperation.
                    </blockquote>
                  </div>
                  <div className='testimonial-bottom'>
                    <div className='testimonial-avatar'>
                      <div className='testimonial-img'>
                        <img className='img-fluid' src='images/Team/testomonial.svg' alt='testimonial-img' />
                      </div>
                    </div>
                    <div className='testimonial-caption'>
                      <label>
                      Bhawani Sharan
                      </label>
                      <h3>Excavator Operator</h3>
                    </div>
                  </div>
                </div>
                {/* testimonials end */}
              </div>
              <div className='col-lg-12'>
                {/* testimonials */}
                <div className='testimonials ttm-testimonial-box-view-style1'>
                  <div className='testimonial-content'>
                    <blockquote className='testimonial-text'>
                    My experience with Atlas India has been very good.  The entire team is very cooperative, and I have a good communication with them.
Specially I have had a good experience with my coordinator from hiring to all other related matters like visa processing, ticket processing and until my mobilisation.
                    </blockquote>
                  </div>
                  <div className='testimonial-bottom'>
                    <div className='testimonial-avatar'>
                      <div className='testimonial-img'>
                        <img className='img-fluid' src='images/Team/testomonial.svg' alt='testimonial-img' />
                      </div>
                    </div>
                    <div className='testimonial-caption'>
                      <label>
                      Azhar Fareed
                      </label>
                      <h3>Mechanic</h3>
                    </div>
                  </div>
                </div>
                {/* testimonials end */}
              </div>
              <div className='col-lg-12'>
                {/* testimonials */}
                <div className='testimonials ttm-testimonial-box-view-style1'>
                  <div className='testimonial-content'>
                    <blockquote className='testimonial-text'>
                    It will soon be a year since I started signing a contract with Atlas Contracting Company. It is a wonderful opportunity that has been given to me and I am delighted to be a part of one of the world's largest oil and gas companies through atlas.In my opinion, your professional approach and great management with me were very much appreciated. With God's grace, the journey may continue. All the best wishes to Atlas and its team for getting good business deals with the best companies and therefore for giving the best candidates the chance to provide sincere services to the partners.
                    </blockquote>
                  </div>
                  <div className='testimonial-bottom'>
                    <div className='testimonial-avatar'>
                      <div className='testimonial-img'>
                        <img className='img-fluid' src='images/Team/testomonial.svg' alt='testimonial-img' />
                      </div>
                    </div>
                    <div className='testimonial-caption'>
                      <label>
                      Aswin Bhargavan
                      </label>
                      <h3>Safety Officer</h3>
                    </div>
                  </div>
                </div>
                {/* testimonials end */}
              </div>
            </Slider>
          </div>
        </section>
        {/* testimonial-section end */}
        {/* contact-section */}
        <section className='ttm-row contact-section bg-theme-GreyColor clearfix'>
          <div className='container'>
            <div className='row'>
              <div className='col-lg-12'>
                {/* section-title */}
                <div className='section-title title-style-center_text'>
                  <div className='title-header'>
                    <h3>Contact us</h3>
                    <h2 className='title'>Get In Touch With Us</h2>
                  </div>
                </div>
                {/* section-title end */}
              </div>
            </div>
            {/* row */}
            <div className='row'>
              <div className='col-lg-6'>
                <div className='pr-30 pr-md-0 mt-15'>
                  <div className='ttm_single_image-wrapper mb-20'>
                    <img className='img-fluid w-100' src='images/About/GetInTouch.png' alt='single_06' />
                  </div>
                  <p className='mb-30'>
                    Reach out to Atlas for all your maritime staffing needs. Our dedicated team is ready to assist you in finding the perfect talent for your operations.
                    <br/>
                    <br/>Connect with us today to discuss how we can support your recruitment goals and help propel your maritime endeavors forward.
                  </p>
                 
                  <div className='d-flex align-items-center'>
                    <div className='ttm-icon ttm-icon_element-fill ttm-icon_element-color-skincolor ttm-icon_element-size-sm
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                            ttm-icon_element-style-round mb-0'>
                      <i className='fa fa-globe'></i>
                    </div>
                    <div className='pl-15'>
                      <h6 className='mb-5'>Send Email</h6>
                      <p className='featured-desc mb-0'>
                        info@atlasindia.co , jobs@atlasindia.co
                      </p>
                    </div>
                  </div>
                  <div className='ttm-horizontal_sep width-100 mt-20 mb-20'></div>
                  {/* <div className='d-flex align-items-center'>
                    <div className='ttm-icon ttm-icon_element-fill ttm-icon_element-color-skincolor ttm-icon_element-size-sm
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                            ttm-icon_element-style-round mb-0'>
                      <i className='fa fa-globe'></i>
                    </div>
                    <div className='pl-15'>
                      <h6 className='mb-5'>Send Email</h6>
                      <p className='featured-desc mb-0'>
                        info@atlasindia.co
                      </p>
                    </div>
                  </div> */}
                   <div className='d-flex align-items-center'>
                    <div className='ttm-icon ttm-icon_element-fill ttm-icon_element-color-skincolor ttm-icon_element-size-sm
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                            ttm-icon_element-style-round mb-0'>
                      <i className='fa fa-phone'></i>
                    </div>
                    <div className='pl-15'>
                      <h6 className='mb-5'>Call Anytime</h6>
                      <p className='featured-desc mb-0'>
                      India:+91 7400 493245 , Qatar:+974 70254338  
                      </p>
                    </div>
                  </div>
                  <div className='ttm-horizontal_sep width-100 mt-20 mb-20'></div>
                  <div className='d-flex align-items-center'>
                    <div className='ttm-icon ttm-icon_element-fill ttm-icon_element-color-skincolor ttm-icon_element-size-sm
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                            ttm-icon_element-style-round mb-0'>
                      <i className='ti ti-home'></i>
                    </div>
                    <div className='pl-15'>
                      <h6 className='mb-5'>Visit Office</h6>
                      <p className='featured-desc mb-0'>
                       India - Mumbai 
                        <br/>  Qatar - Doha 
                        <br/>  UAE - Dubai 
                      </p>
                    </div>
                  </div>
                  {/*  featured-icon-box end */}
                </div>
              </div>
              <GetInTouch/>
            </div>
          </div>
        </section>
        {/* contact-section end */}
        {/* google_map */}
        <div id='google_map' className='google_map'>
          <div className='map_container clearfix'>
            <div id='map'>
              <iframe src='https://www.google.com/maps/d/u/0/embed?mid=1nJNCCAS0hOULRr10hRRcstuu_8D7jdQ&ehbc=2E312F&noprof=1' width='100%' height='550'></iframe>
            </div>
          </div>
        </div>
        {/* google_map end */}
        {/* action-section */}
        <section className='ttm-row action-section bg-theme-SkinColor text-theme-WhiteColor clearfix'>
          <div className='container'>
            <div className='row'>
              <div className='col-lg-12'>
                <div className='d-md-flex align-items-center justify-content-between'>
                  {/* featured-icon-box */}
                  <div className='featured-icon-box icon-align-before-content style2'>
                    <div className='featured-icon'>
                      <div className='ttm-icon ttm-icon_element-onlytxt ttm-icon_element-color-white ttm-icon_element-size-xl'>
                        <i className='flaticon flaticon-recruitment-5'></i>
                      </div>
                    </div>
                    <div className='featured-content'>
                      <div className='featured-title'>
                        <h3>Incredible Recruitment & Staffing Specialist</h3>
                      </div>
                      <div className='featured-desc'>
                        {/* <p>
                          We have over 30 years experience oft Business porro qusquam dol ipsum quia dolor sit amet.
                        </p> */}
                      </div>
                    </div>
                  </div>
                  {/* featured-icon-box end */}
                  <a className='ttm-btn ttm-btn-size-md ttm-btn-shape-rounded ttm-btn-style-border ttm-btn-color-white'href="/carrer_01?focus=true">Hiring Now!</a>
                  {/* <button id="submit_button" onClick={() => window.location.href = 'Contact_01'}>
      Hiring Button
    </button> */}
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* action-section end */}
        <Footer/>
      </div>
    )
  }
}

export default About_us
