import React, { Component } from 'react'
import Header from '../components/layout/Header'
import PageHeader from '../components/layout/PageHeader'
import { Footer } from '../components/layout/Footer'
import GetInTouch from '../components/GetInTouch'

export class Contact_01 extends Component {
  render () {
    return (
      <div className='site-main'>
        <Header />
        {/* PageHeader */}
        <PageHeader title='Contact us' breadcrumb='Contact' />
        {/* PageHeader end */}
        {/* grid-section */}
        <section className='ttm-row grid-section clearfix'>
          <div className='container'>
            {/* row */}
            <div className='row'>
              <div className='col-lg-4 col-md-6'>
                <div className='featured-imagebox box-shadow'>
                  <div className='featured-thumbnail'>
                    <img className='img-fluid' src='images/slides/Office/1.png' alt='country-01' />
                  </div>
                  <div className='featured-content p-30'>
                    <div className='featured-title'>
                      <h3><a href={process.env.PUBLIC_URL + '/'}>India - Mumbai</a></h3>
                    </div>
                    <div className='featured-desc'>
                      <ul className='ttm-list ttm-list-style-icon ttm-list-icon-color-skincolor'>
                        <li className='pb-5'>
                          <i className='fas fa-map-marker-alt'></i>
                          <div className='ttm-list-li-content'>
                            Addres: Mumbai
                          </div>
                        </li>
                       
                        <li>
                          <i className='fa fa-envelope'></i>
                          <div className='ttm-list-li-content'>
                            <a href='mailto:info@atlasindia.co'>Email:info@atlasindia.co   jobs@atlasindia.co</a>
                          </div>
                        </li>
                        <li className='pb-5'>
                          <i className='fas fa-phone-alt'></i>
                          <div className='ttm-list-li-content'>
                          India +91 7400 493245
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div className='col-lg-4 col-md-6'>
                <div className='featured-imagebox box-shadow'>
                  <div className='featured-thumbnail'>
                    <img className='img-fluid' src='images/slides/Office/2.png' alt='country-02' />
                  </div>
                  <div className='featured-content p-30'>
                    <div className='featured-title'>
                      <h3><a href={process.env.PUBLIC_URL + '/'}>Qatar - Doha</a></h3>
                    </div>
                    <div className='featured-desc'>
                      <ul className='ttm-list ttm-list-style-icon ttm-list-icon-color-skincolor'>
                        <li className='pb-5'>
                          <i className='fas fa-map-marker-alt'></i>
                          <div className='ttm-list-li-content'>
                            Addres: Qatar - Doha 
                          </div>
                        </li>
                        <li>
                          <i className='fa fa-envelope'></i>
                          <div className='ttm-list-li-content'>
                            <a href='mailto:info@atlasindia.co'>Email:info@atlasindia.co  jobs@atlasindia.co</a>
                          </div>
                        </li>
                        <li className='pb-5'>
                          <i className='fas fa-phone-alt'></i>
                          <div className='ttm-list-li-content'>
                            Qatar:+974 70254338 
                          </div>
                        </li>
                       
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div className='col-lg-4 col-md-6'>
                <div className='featured-imagebox box-shadow'>
                  <div className='featured-thumbnail'>
                    <img className='img-fluid' src='images/slides/Office/3.png' alt='country-03' />
                  </div>
                  <div className='featured-content p-30'>
                    <div className='featured-title'>
                      <h3><a href={process.env.PUBLIC_URL + '/'}>UAE - Dubai</a></h3>
                    </div>
                    <div className='featured-desc'>
                      <ul className='ttm-list ttm-list-style-icon ttm-list-icon-color-skincolor'>
                        <li className='pb-5'>
                          <i className='fas fa-map-marker-alt'></i>
                          <div className='ttm-list-li-content'>
                            Addres: Dubai
                          </div>
                        </li>
                        <li className='pb-5'>
                          <i className='fas fa-phone-alt'></i>
                          <div className='ttm-list-li-content'>
                          
                          </div>
                        </li>
                        <li>
                          <i className='fa fa-envelope'></i>
                          <div className='ttm-list-li-content'>
                            <a href='mailto:info@atlasindia.co'>Email:info@atlasindia.co  jobs@atlasindia.co</a>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* row end */}
          </div>
        </section>
        {/* grid-section end */}
        {/* map-section */}
        {/* <GetInTouch/> */}
        <section className='ttm-row map-section bg-theme-GreyColor clearfix'>
          <div className='container'>
            {/* row */}
            <div className='row'>
              <div className='col-lg-12'>
                {/* section title */}
                <div className='section-title title-style-center_text pb-15'>
                  <div className='title-header'>
                    <h3>get in <span className='text-theme-SkinColor'>touch</span></h3>
                    <h2 className='title'>Feel Free To Contact</h2>
                  </div>
                </div>
                {/* section title end */}
              </div>
              <div className='col-lg-6'>
                <iframe height='530' width='100%' src='https://www.google.com/maps/d/u/0/embed?mid=1nJNCCAS0hOULRr10hRRcstuu_8D7jdQ&ehbc=2E312F&noprof=1' />
              </div>
            <GetInTouch/>
            </div>
            {/* row end */}
          </div>
        </section>
        {/* map-section end */}
        {/* action-section */}
        <section className='ttm-row action-section bg-theme-SkinColor text-theme-WhiteColor clearfix'>
          <div className='container'>
            <div className='row'>
              <div className='col-lg-12'>
                <div className='d-md-flex align-items-center justify-content-between'>
                  {/* featured-icon-box */}
                  <div className='featured-icon-box icon-align-before-content style2'>
                    <div className='featured-icon'>
                      <div className='ttm-icon ttm-icon_element-onlytxt ttm-icon_element-color-white ttm-icon_element-size-xl'>
                        <i className='flaticon flaticon-recruitment-5'></i>
                      </div>
                    </div>
                    <div className='featured-content'>
                      <div className='featured-title'>
                        <h3>Incredible Recruitment & Staffing Specialist</h3>
                      </div>
                      <div className='featured-desc'>
                        {/* <p>
                          We have over 30 years experience oft Business porro qusquam dol ipsum quia dolor sit amet.
                        </p> */}
                      </div>
                    </div>
                  </div>
                  {/* featured-icon-box end */}
                  <a className='ttm-btn ttm-btn-size-md ttm-btn-shape-rounded ttm-btn-style-border ttm-btn-color-white' href={process.env.PUBLIC_URL + '/carrer_01?focus=true'}>Hiring Now!</a>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* action-section end */}
        <Footer/>
      </div>
    )
  }
}

export default Contact_01
